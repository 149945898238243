import React, { PureComponent } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { PlayingVod, Run, RunTableCol, SortDir } from '../../types';
import { GlobalContext } from '../App/GlobalContext';
import { PAGE_SIZE } from './RunTableContainer';
import { RunTableHeaderRow } from './RunTableHeaderRow';
import { RunTableLoadingRow } from './RunTableLoadingRow';
import { RunTablePlayingRow } from './RunTablePlayingRow';
import { RunTableStandardRow } from './RunTableStandardRow';

interface Props {
  cols: RunTableCol[];
  runPage: Run[];
  allRuns: Run[];
  sortedCol: RunTableCol;
  sortedDir: SortDir;
  playingVod?: PlayingVod;
  randomRun?: Run;
  handleSort: (col: RunTableCol) => void;
  playRun: (run: Run) => void;
  playRandomRun: () => void;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
  closePlayingVod: () => void;
  autoplayRun: (run: Run) => void;
  loading: boolean;
  page: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
}

class RunTableComponent extends PureComponent<Props> {
  public static contextType = GlobalContext;

  public render() {
    const {
      cols,
      runPage,
      allRuns,
      sortedCol,
      sortedDir,
      playingVod,
      handleSort,
      playRandomRun,
      setPlayingVod,
      closePlayingVod,
      autoplayRun,
      loading,
      page,
      totalPages,
      randomRun,
    } = this.props;
    const { darkMode, watchedRunIds } = this.context;

    return (
      <Table compact={true} sortable={true} inverted={darkMode}>
        <Table.Header>
          <RunTableHeaderRow
            cols={cols}
            sortedCol={sortedCol}
            sortedDir={sortedDir}
            handleSort={handleSort}
            playRandomRun={playRandomRun}
          />
        </Table.Header>
        <Table.Body>
          {loading && [...Array(30)].map((_, i) => <RunTableLoadingRow key={`loading-card-${i}`} cols={cols} />)}
          {!loading &&
            runPage.map((run, i) => {
              const runIndex = i + page * PAGE_SIZE;
              if (playingVod && playingVod.runId === run.id) {
                const runsBefore = runIndex > 0 ? allRuns.slice(0, runIndex).filter(r => r.vods.length > 0) : [];
                const runsAfter =
                  runIndex < allRuns.length - 1 ? allRuns.slice(runIndex + 1).filter(r => r.vods.length > 0) : [];
                const prevRun = runsBefore.length > 0 ? runsBefore[runsBefore.length - 1] : undefined;
                const nextRun = runsAfter.length > 0 ? runsAfter[0] : undefined;

                return (
                  <RunTablePlayingRow
                    key={`playing-row-${run.id}`}
                    cols={cols}
                    run={run}
                    isWatched={watchedRunIds.has(run.id)}
                    vodIndex={playingVod.vodIndex}
                    videoIndex={playingVod.videoIndex}
                    setPlayingVod={setPlayingVod}
                    closePlayingVod={closePlayingVod}
                    prevRun={prevRun}
                    nextRun={nextRun}
                    randomRun={randomRun}
                    playRun={this.playRun}
                    autoplayRun={autoplayRun}
                  />
                );
              } else {
                const isWatched = watchedRunIds.has(run.id);
                return (
                  <RunTableStandardRow
                    key={`standard-row-${isWatched ? 'watched' : 'unwatched'}-${run.id}`}
                    run={run}
                    cols={cols}
                    isWatched={isWatched}
                    darkMode={darkMode}
                    setPlayingVod={setPlayingVod}
                  />
                );
              }
            })}
        </Table.Body>
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={cols.length} style={{ textAlign: 'center' }}>
                <Pagination
                  activePage={page + 1}
                  totalPages={totalPages}
                  onPageChange={this.onPageChange}
                  firstItem={null}
                  lastItem={null}
                  inverted={darkMode}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    );
  }

  private onPageChange = (e: any, { activePage }: any) => {
    const { handlePageChange } = this.props;
    handlePageChange(activePage - 1);
  };

  private playRun = (run: Run | undefined) => {
    const { isRandomOn } = this.context;
    const { randomRun, setPlayingVod } = this.props;

    if (isRandomOn) {
      if (randomRun) {
        setPlayingVod(randomRun.id);
      }
    } else if (run) {
      setPlayingVod(run.id);
    }
  };
}

export { RunTableComponent };
