import React, { PureComponent } from 'react';
import { Message } from 'semantic-ui-react';

class ErrorMessage extends PureComponent {
  public render() {
    return (
      <Message negative={true}>
        <Message.Header>We've run into a problem</Message.Header>
        <p>
          Try reloading the page, and if you keep seeing this error please send an email to{' '}
          <a href="mailto:email.gdqvods@gmail.com">email.gdqvods@gmail.com</a>.
        </p>
      </Message>
    );
  }
}

export default ErrorMessage;
export { ErrorMessage };
