import gql from 'graphql-tag';

const runTableRunFields = `
  id
  startTime
  runCategory
  duration
  event {
    id
    shortName
    startDate
    timezone
  }
  game {
    id
    name
    year
    platform {
      id
      name
    }
    genre {
      id
      name
    }
  }
  runners {
    id
    name
    website
    websiteName
    twitch
    twitter
    youtube
    facebook
  }
  siteCategories {
    id
    name
  }
  vods {
    source
    videoIds
    isPrimary
    start
    info
  }
`;

export const HIGHLIGHTED_RUNS_QUERY = gql`
  {
    runs(siteCategories: ["highlights"]) {
      ${runTableRunFields}
    }
  }
`;

export const EVENT_RUNS_QUERY = gql`
  query eventRuns($event: String) {
    runs(events: [$event]) {
      ${runTableRunFields}
    }
  }
`;

export const GAME_RUNS_QUERY = gql`
  query gameRuns($game: String) {
    runs(games: [$game]) {
      ${runTableRunFields}
    }
  }
`;

export const GENRE_RUNS_QUERY = gql`
  query genreRuns($genre: String) {
    runs(genres: [$genre]) {
      ${runTableRunFields}
    }
  }
`;

export const PLATFORM_RUNS_QUERY = gql`
  query platformRuns($platform: String) {
    runs(platforms: [$platform]) {
      ${runTableRunFields}
    }
  }
`;

export const RUNNER_RUNS_QUERY = gql`
  query runnerRuns($runner: String) {
    runs(runners: [$runner]) {
      ${runTableRunFields}
    }
  }
`;

export const CATEGORY_RUNS_QUERY = gql`
  query categoryRuns($category: String) {
    runs(siteCategories: [$category]) {
      ${runTableRunFields}
    }
  }
`;

export const EVENT_QUERY = gql`
  query eventData($event: String) {
    events(ids: [$event]) {
      name
      shortName
      startDate
      endDate
      totalRaised
      charity
      extraInfo
      description
      shortDescription
    }
  }
`;

export const GAME_QUERY = gql`
  query gameData($game: String) {
    games(ids: [$game]) {
      name
      genre {
        name
      }
      platform {
        name
      }
      year
      description
      franchiseCategory {
        id
        name
      }
    }
  }
`;

export const GENRE_QUERY = gql`
  query genreData($genre: String) {
    genres(ids: [$genre]) {
      name
      description
    }
  }
`;

export const PLATFORM_QUERY = gql`
  query platformData($platform: String) {
    platforms(ids: [$platform]) {
      id
      name
      description
      company
      launchYear
      runCount
    }
  }
`;

export const RUNNER_QUERY = gql`
  query runnerData($runner: String) {
    runners(ids: [$runner]) {
      name
      website
      twitch
      twitter
      youtube
      facebook
      description
    }
  }
`;

export const CATEGORY_QUERY = gql`
  query categoryData($category: String) {
    siteCategories(ids: [$category]) {
      id
      name
      description
    }
  }
`;

export const EVENTS_QUERY = gql`
  query events {
    events {
      id
      name
      shortName
      startDate
      endDate
      totalRaised
      charity
      description
      shortDescription
    }
  }
`;

export const GENRES_QUERY = gql`
  query genres {
    genres {
      id
      name
      description
      shortDescription
    }
  }
`;

export const CATEGORIES_QUERY = gql`
  query siteCategories {
    siteCategories {
      id
      name
      description
      shortDescription
    }
  }
`;

export const GAMES_QUERY = gql`
  query games {
    games {
      id
      name
      year
      description
      runCount
      genre {
        id
        name
      }
      platform {
        id
        name
      }
    }
  }
`;

export const PLATFORMS_QUERY = gql`
  query platforms {
    platforms {
      id
      name
      description
      company
      launchYear
      runCount
    }
  }
`;

export const RUNNERS_QUERY = gql`
  query runners {
    runners {
      id
      name
      website
      websiteName
      twitch
      twitter
      youtube
      facebook
      runCount
    }
  }
`;
