import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { Run, RunTableCol } from '../../types';
import { GlobalContext } from '../App/GlobalContext';
import { PlayingVodCell } from './PlayingVodCell';

interface Props {
  cols: RunTableCol[];
  run: Run;
  isWatched: boolean;
  vodIndex: number;
  videoIndex: number;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
  closePlayingVod: () => void;
  nextRun?: Run;
  prevRun?: Run;
  randomRun?: Run;
  playRun: (run: Run | undefined) => void;
  autoplayRun: (run: Run) => void;
}

class RunTablePlayingRow extends PureComponent<Props> {
  public static contextType = GlobalContext;

  public render() {
    const {
      run,
      vodIndex,
      videoIndex,
      cols,
      isWatched,
      setPlayingVod,
      closePlayingVod,
      prevRun,
      nextRun,
      randomRun,
    } = this.props;
    const { isRandomOn } = this.context;
    const canPlayRandom = isRandomOn && randomRun;
    const canPlayPrev = !isRandomOn && prevRun;
    const canPlayNext = !isRandomOn && nextRun;

    return (
      <Table.Row>
        <PlayingVodCell
          run={run}
          colSpan={cols.length}
          vodIndex={vodIndex}
          videoIndex={videoIndex}
          isWatched={isWatched}
          setPlayingVod={setPlayingVod}
          closePlayingVod={closePlayingVod}
          playPrevRun={(canPlayPrev || canPlayRandom) && this.playPrevRun}
          playNextRun={(canPlayNext || canPlayRandom) && this.playNextRun}
          onVideoEnded={this.onVideoEnded}
        />
      </Table.Row>
    );
  }

  private playPrevRun = () => {
    const { prevRun, playRun } = this.props;
    playRun(prevRun);
  };

  private playNextRun = () => {
    const { nextRun, playRun } = this.props;
    playRun(nextRun);
  };

  private onVideoEnded = () => {
    const { isAutoplayOn } = this.context;
    const { nextRun, autoplayRun } = this.props;
    if (isAutoplayOn && nextRun) {
      autoplayRun(nextRun);
    }
  };
}

export { RunTablePlayingRow };
