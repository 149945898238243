import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import App from './components/App';
import ScrollToTop from './components/App/ScrollToTop';
// import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/semantic.min.css';
import './index.css';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');
ReactGA.set({ anonymizeIp: true });

const hostname = window && window.location && window.location.hostname;
const prodHostnames = (process.env.REACT_APP_PROD_HOSTNAMES || '').split(',');
const isProd = prodHostnames.indexOf(hostname) > -1;
const useGraphOS = window.location.href.endsWith('?graphos=true');

const cache = new InMemoryCache();

const apiBaseUrl = useGraphOS
  ? process.env.REACT_APP_GRAPHOS_BASE_URL
  : isProd
  ? process.env.REACT_APP_PROD_API_BASE_URL || ''
  : 'http://localhost:4000';

const httpLink = new HttpLink({
  uri: `${apiBaseUrl}/graphql`,
});

// createPersistedQueryLink is used so that we send GET requests that can be cached in CloudFront
const link = ApolloLink.from([createPersistedQueryLink({ useGETForHashedQueries: true }), httpLink]);

const options: DefaultOptions = {
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  },
  watchQuery: {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  },
};

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const client = new ApolloClient({
  cache,
  defaultOptions: options,
  link,
});

class Root extends Component {
  public componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  public render() {
    return (
      <ApolloProvider client={client}>
        <Router history={history}>
          <ScrollToTop />
          <App />
        </Router>
      </ApolloProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// serviceWorker.register();
