/* tslint:disable:max-classes-per-file */

import React, { PureComponent, ReactNode } from 'react';
import { Table, TableHeaderCellProps } from 'semantic-ui-react';
import { RunTableCol } from '../../types';
import { GlobalContext } from '../App/GlobalContext';

const headerCellStyles = (widthOverride?: string | null, sortable?: boolean, darkMode?: boolean) => {
  const styles: any = {};
  if (widthOverride) {
    styles.width = widthOverride;
  }
  if (!sortable) {
    styles.cursor = 'auto';
    styles.backgroundColor = darkMode ? 'rgba(0, 0, 0, 0.15)' : 'rgb(249, 250, 251)';
  }

  return styles;
};

export interface HeaderCellProps extends TableHeaderCellProps {
  widthOverride?: string;
  sortable: boolean;
  col: RunTableCol;
  playRandomRun: () => void;
  handleSort: (col: RunTableCol) => void;
  children?: ReactNode;
}

class BaseHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    const { children, widthOverride, sortable, col, playRandomRun, handleSort, ...rest } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Table.HeaderCell
            style={headerCellStyles(widthOverride, sortable, darkMode)}
            onClick={this.onClick}
            {...rest}
          >
            {children}
          </Table.HeaderCell>
        )}
      </GlobalContext.Consumer>
    );
  }

  private onClick = () => {
    const { sortable, col, handleSort } = this.props;
    if (sortable) {
      handleSort(col);
    }
  };
}

class StartTimeHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Start Time</BaseHeaderCell>;
  }
}

class GameHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Game</BaseHeaderCell>;
  }
}

class GameNoYearHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Game</BaseHeaderCell>;
  }
}

class YearHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Year</BaseHeaderCell>;
  }
}

class PlatformHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Platform</BaseHeaderCell>;
  }
}

class CategoryHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Category</BaseHeaderCell>;
  }
}

class GenreHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Genre</BaseHeaderCell>;
  }
}

class EventHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Event</BaseHeaderCell>;
  }
}

class RunnersHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return (
      <BaseHeaderCell widthOverride="25%" {...this.props}>
        Runners
      </BaseHeaderCell>
    );
  }
}

class TimeHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Time</BaseHeaderCell>;
  }
}

class VodsHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return (
      <BaseHeaderCell textAlign="center" {...this.props}>
        VODs
        <br />
        <button onClick={this.props.playRandomRun} className="link" style={{ color: '#4183c4' }}>
          Random
        </button>
      </BaseHeaderCell>
    );
  }
}

class WorldRecordHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>WR</BaseHeaderCell>;
  }
}

class HandicappedHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Handicapped</BaseHeaderCell>;
  }
}

class DevCommentaryHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Dev Commentary</BaseHeaderCell>;
  }
}

class RaceHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>Race</BaseHeaderCell>;
  }
}

class TasHeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    return <BaseHeaderCell {...this.props}>TAS</BaseHeaderCell>;
  }
}

export class HeaderCell extends PureComponent<HeaderCellProps> {
  public render() {
    const { col } = this.props;
    switch (col) {
      case RunTableCol.StartTime:
        return <StartTimeHeaderCell {...this.props} />;
      case RunTableCol.Game:
        return <GameHeaderCell {...this.props} />;
      case RunTableCol.GameNoYear:
        return <GameNoYearHeaderCell {...this.props} />;
      case RunTableCol.Year:
        return <YearHeaderCell {...this.props} />;
      case RunTableCol.Platform:
        return <PlatformHeaderCell {...this.props} />;
      case RunTableCol.Category:
        return <CategoryHeaderCell {...this.props} />;
      case RunTableCol.Genre:
        return <GenreHeaderCell {...this.props} />;
      case RunTableCol.Event:
        return <EventHeaderCell {...this.props} />;
      case RunTableCol.Runners:
        return <RunnersHeaderCell {...this.props} />;
      case RunTableCol.Time:
        return <TimeHeaderCell {...this.props} />;
      case RunTableCol.Vods:
        return <VodsHeaderCell {...this.props} />;
      case RunTableCol.WorldRecord:
        return <WorldRecordHeaderCell {...this.props} />;
      case RunTableCol.Handicapped:
        return <HandicappedHeaderCell {...this.props} />;
      case RunTableCol.DevCommentary:
        return <DevCommentaryHeaderCell {...this.props} />;
      case RunTableCol.Race:
        return <RaceHeaderCell {...this.props} />;
      case RunTableCol.Tas:
        return <TasHeaderCell {...this.props} />;
      default:
        return null;
    }
  }
}

export default HeaderCell;
