import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { GENRES_QUERY } from '../../apollo';
import { GenreQueryData } from '../../types';
import { sortGenres } from '../../utils';
import { GenreCard } from '../Cards';
import StandardHelmet from '../Shared/StandardHelmet';
import CardIndexPage from './CardIndexPage';

const title = 'GDQ VODs · Genres';
const description =
  'Browse through the VODs for all the genres of games that have been played at Games Done Quick events.';

class GenresPage extends PureComponent {
  public render() {
    return (
      <Query<GenreQueryData> query={GENRES_QUERY}>
        {({ data, error, loading }: QueryResult<GenreQueryData>) => {
          const genres = this.getGenres(data);
          const loadingFirstTime = loading && genres.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <CardIndexPage adKey="GenresPage" loading={loadingFirstTime} error={!!error} numPlaceholders={13}>
                {genres
                  .slice()
                  .sort(sortGenres)
                  .map((genre, i) => (
                    <GenreCard key={i} genre={genre} />
                  ))}
              </CardIndexPage>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getGenres(data?: GenreQueryData) {
    return (data && data.genres) || [];
  }
}

export default GenresPage;
export { GenresPage };
