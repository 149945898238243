import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Container, Header } from 'semantic-ui-react';
import { CATEGORY_QUERY } from '../../apollo';
import { CategoryQueryData, CategoryQueryVars, SiteCategory } from '../../types';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  categoryId: string;
}

class SiteCategorySegment extends PureComponent<Props> {
  public render() {
    const { categoryId } = this.props;
    return (
      <Query<CategoryQueryData, CategoryQueryVars> query={CATEGORY_QUERY} variables={{ category: categoryId }}>
        {({ data, error, loading }: QueryResult<CategoryQueryData, CategoryQueryVars>) => {
          const category = this.getCategory(data);
          const loadingFirstTime = loading && !category;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && category && (
                  <Fragment>
                    <StandardHelmet
                      title={this.getTitle(category)}
                      description={this.getDescription(categoryId, category)}
                    />
                    <Header>{category.name}</Header>
                    <Markdown markdown={`${category.description}\n\n${this.getDescription(categoryId, category)}`} />
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(category?: SiteCategory) {
    const title = 'GDQ VODs';
    return category ? `${title} · ${category.name}` : title;
  }

  private getDescription(categoryId: string, category?: SiteCategory) {
    if (categoryId === 'all') {
      return `Watch the VODs of every single run here and browse through related games, runners, and special categories.`;
    }
    const nameToUse = category ? category.name : categoryId;
    return `Watch the VODs of all ${nameToUse} runs here and browse through related games, runners, and special categories.`;
  }

  private getCategory(categoryData?: CategoryQueryData) {
    return categoryData && categoryData.siteCategories ? categoryData.siteCategories[0] : undefined;
  }
}

export default SiteCategorySegment;
export { SiteCategorySegment };
