/* tslint:disable:max-classes-per-file */

import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { Run, RunTableCol } from '../../types';
import { humanDateTime, siteCategoryBool } from '../../utils';
import { EventLink, GameNoYearLink, GameWithYearLink, GenreLink, PlatformLink, RunnerLinks } from '../Shared/MiscLinks';
import { VodLinks } from '../Shared/VodLinks';

interface BodyCellProps {
  key: string;
  run: Run;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
  col: RunTableCol;
}

export class StartTimeCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key} style={{ whiteSpace: 'nowrap' }}>
        {humanDateTime(run.startTime, run.event.timezone)}
      </Table.Cell>
    );
  }
}

export class PlatformCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key} style={{ whiteSpace: 'nowrap' }}>
        <PlatformLink platform={run.game.platform} />
      </Table.Cell>
    );
  }
}

export class GameCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key}>
        <GameWithYearLink game={run.game} />
      </Table.Cell>
    );
  }
}

export class GameNoYearCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key}>
        <GameNoYearLink game={run.game} />
      </Table.Cell>
    );
  }
}

export class YearCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{run.game.year}</Table.Cell>;
  }
}

export class CategoryCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{run.runCategory}</Table.Cell>;
  }
}

export class GenreCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key} style={{ whiteSpace: 'nowrap' }}>
        <GenreLink genre={run.game.genre} />
      </Table.Cell>
    );
  }
}

export class EventCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key} style={{ whiteSpace: 'nowrap' }}>
        <EventLink event={run.event} />
      </Table.Cell>
    );
  }
}

export class RunnersCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key}>
        <RunnerLinks runners={run.runners} showPopup={false} />
      </Table.Cell>
    );
  }
}

export class TimeCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return (
      <Table.Cell key={key}>
        <span className="runTime">{run.duration}</span>
      </Table.Cell>
    );
  }
}

export class VodsCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, setPlayingVod, key } = this.props;
    return (
      <Table.Cell key={key} textAlign="center" style={{ whiteSpace: 'nowrap' }}>
        <VodLinks run={run} setPlayingVod={setPlayingVod} showPopup={false} />
      </Table.Cell>
    );
  }
}

const formatSiteCategoryCell = (run: Run, catId: string) =>
  siteCategoryBool(!!run.siteCategories.find(c => c.id === catId));

export class WorldRecordCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{formatSiteCategoryCell(run, 'world-record-runs')}</Table.Cell>;
  }
}

export class HandicappedCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{formatSiteCategoryCell(run, 'handicapped-runs')}</Table.Cell>;
  }
}

export class DevCommentaryCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{formatSiteCategoryCell(run, 'developer-commentaries')}</Table.Cell>;
  }
}

export class RaceCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{formatSiteCategoryCell(run, 'races')}</Table.Cell>;
  }
}

export class TasCell extends PureComponent<BodyCellProps> {
  public render() {
    const { run, key } = this.props;
    return <Table.Cell key={key}>{formatSiteCategoryCell(run, 'tool-assisted-speedruns')}</Table.Cell>;
  }
}

export class BodyCell extends PureComponent<BodyCellProps> {
  public render() {
    const { col } = this.props;
    switch (col) {
      case RunTableCol.StartTime:
        return <StartTimeCell {...this.props} />;
      case RunTableCol.Game:
        return <GameCell {...this.props} />;
      case RunTableCol.GameNoYear:
        return <GameNoYearCell {...this.props} />;
      case RunTableCol.Year:
        return <YearCell {...this.props} />;
      case RunTableCol.Platform:
        return <PlatformCell {...this.props} />;
      case RunTableCol.Category:
        return <CategoryCell {...this.props} />;
      case RunTableCol.Genre:
        return <GenreCell {...this.props} />;
      case RunTableCol.Event:
        return <EventCell {...this.props} />;
      case RunTableCol.Runners:
        return <RunnersCell {...this.props} />;
      case RunTableCol.Time:
        return <TimeCell {...this.props} />;
      case RunTableCol.Vods:
        return <VodsCell {...this.props} />;
      case RunTableCol.WorldRecord:
        return <WorldRecordCell {...this.props} />;
      case RunTableCol.Handicapped:
        return <HandicappedCell {...this.props} />;
      case RunTableCol.DevCommentary:
        return <DevCommentaryCell {...this.props} />;
      case RunTableCol.Race:
        return <RaceCell {...this.props} />;
      case RunTableCol.Tas:
        return <TasCell {...this.props} />;
      default:
        return null;
    }
  }
}

export default BodyCell;
