export interface Event {
  id: string;
  name: string;
  shortName: string;
  startDate: string;
  endDate: string;
  timezone: string;
  totalRaised: number;
  charity: string;
  description?: string;
  shortDescription?: string;
  extraInfo?: string;
}

export interface Platform {
  id: string;
  name: string;
  shortDesc: string;
  description: string;
  company: string;
  launchYear: number;
  runCount: number;
}

export interface Genre {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
}

export interface Game {
  id: string;
  name: string;
  year: number;
  platform: Platform;
  genre: Genre;
  description?: string;
  franchiseCategory?: SiteCategory;
  runCount: number;
}

export interface Runner {
  id: string;
  name: string;
  description: string;
  website: string;
  websiteName: string;
  twitch: string;
  twitter: string;
  youtube: string;
  facebook: string;
  runCount: number;
}

export interface SiteCategory {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
}

export enum VodSource {
  YouTube = 'YOUTUBE',
  Twitch = 'TWITCH',
}

export interface Vod {
  source: VodSource;
  videoIds: string[];
  isPrimary: boolean;
  start?: string;
  end?: string;
  info?: string;
}

export interface IndexedVod {
  vod: Vod;
  index: number;
}

export interface Run {
  id: string;
  startTime: number;
  runCategory: string | null;
  duration: string;
  event: Event;
  game: Game;
  runners: Runner[];
  siteCategories: SiteCategory[];
  vods: Vod[];
}

export interface PlayingVod {
  runId: string;
  vodIndex: number;
  videoIndex: number;
}

export interface RunQueryData {
  isAutoplayOn: boolean;
  isRandomOn: boolean;
  watchedRunIds: string[];
  runs: Run[];
}

export interface EventQueryVars {
  event: string;
}

export interface EventQueryData {
  events: Event[];
  darkMode: boolean;
}

export interface GameQueryVars {
  game: string;
}

export interface GameQueryData {
  games: Game[];
}

export interface GenreQueryVars {
  genre: string;
}

export interface GenreQueryData {
  genres: Genre[];
}

export interface PlatformQueryVars {
  platform: string;
}

export interface PlatformQueryData {
  platforms: Platform[];
}

export interface RunnerQueryVars {
  runner: string;
}

export interface RunnerQueryData {
  runners: Runner[];
}

export interface CategoryQueryVars {
  category: string;
}

export interface CategoryQueryData {
  siteCategories: SiteCategory[];
}

// "Time" = the duration of the run, "Category" = the run category e.g. Any%, "Year" = game's release year
export enum RunTableCol {
  Vods,
  StartTime,
  Game,
  GameNoYear,
  Platform,
  Category,
  Event,
  Runners,
  Time,
  Genre,
  Year,
  WorldRecord,
  Handicapped,
  DevCommentary,
  Race,
  Tas,
}

export enum PlatformTableCol {
  Name,
  Company,
  LaunchYear,
  RunCount,
}

export enum GameTableCol {
  Name,
  Year,
  Platform,
  Genre,
  RunCount,
}

export enum RunnerTableCol {
  Name,
  Links,
  RunCount,
}

export enum SortDir {
  Ascending,
  Descending,
}

export interface GlobalContextType {
  darkMode: boolean;
  isAutoplayOn: boolean;
  isRandomOn: boolean;
  watchedRunIds: Set<string>;
  toggleDarkMode: () => void;
  toggleAutoplay: () => void;
  toggleHideRunTimes: () => void;
  toggleRandom: () => void;
  addWatchedRun: (runId: string) => void;
  clearWatchedRuns: () => void;
  clearWatchedRun: (runId: string) => void;
  hideRunTimes: boolean;
}
