import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon, SearchResultProps, SemanticICONS } from 'semantic-ui-react';

const SearchResultTypeToIcon: { [type: string]: SemanticICONS } = {
  GAME: 'gamepad',
  GENRE: 'tag',
  PLATFORM: 'disk',
  RUNNER: 'user',
};

class SearchResultComponent extends PureComponent<SearchResultProps> {
  public render() {
    return (
      <Fragment>
        <Link to={this.props.link} style={{ color: 'inherit' }}>
          <Icon name={SearchResultTypeToIcon[this.props.type]} /> {this.props.title}
        </Link>
      </Fragment>
    );
  }
}

export default SearchResultComponent;
export { SearchResultComponent };
