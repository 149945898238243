import React, { PureComponent } from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import { GlobalContext } from '../App/GlobalContext';
import { StandardHelmet } from '../Shared/StandardHelmet';

const title = 'GDQ VODs · Privacy Policy';
const description = 'Privacy Policy of GDQ VODs.';

class PrivacyPolicyPage extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <>
            <StandardHelmet title={title} description={description} />
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <Segment textAlign="left" size="large" inverted={darkMode}>
                <Header>Privacy Policy of https://gdqvods.com</Header>
                <p>At GDQ VODs, we collect and manage user data according to the following Privacy Policy.</p>

                <h3>YouTube Data</h3>
                <p>
                  Some of the data shown in this website (VOD links and timestamps) were obtained from YouTube using
                  YouTube API Services. Google's privacy policy can be found{' '}
                  <a href="https://policies.google.com/privacy">here</a>.
                </p>

                <h3>Data Collected</h3>
                <p>
                  We collect information you provide directly to us. For example, we collect information when you create
                  an account, subscribe, participate in any interactive features of our services, fill out a form,
                  request customer support or otherwise communicate with us. The types of information we may collect
                  include your name, email address, postal address, credit card information and other contact or
                  identifying information you choose to provide.
                </p>
                <p>
                  We collect anonymous data from every visitor of the Website to monitor traffic and fix bugs. For
                  example, we collect information like web requests, the data sent in response to such requests, the
                  Internet Protocol address, the browser type, the browser language, and a timestamp for the request.
                </p>
                <p>
                  We also use various technologies to collect information, and this may include sending cookies to your
                  computer. Cookies are small data files stored on your hard drive or in your device memory that helps
                  us to improve our services and your experience, see which areas and features of our services are
                  popular and count visits. We may also collect information using web beacons (also known as "tracking
                  pixels"). Web beacons are electronic images that may be used in our services or emails and to track
                  count visits or understand usage and campaign effectiveness.
                </p>

                <h3>Use of the Data</h3>
                <p>
                  We only use your personal information to provide you the GDQ VODs services or to communicate with you
                  about the Website or the services. Our Privacy Policy was created with the help of the Privacy Policy
                  Template and the Terms and Conditions Template.
                </p>
                <p>
                  We employ industry standard techniques to protect against unauthorized access of data about you that
                  we store, including personal information.
                </p>
                <p>We do not share personal information you have provided to us without your consent, unless:</p>
                <ul>
                  <li>Doing so is appropriate to carry out your own request</li>
                  <li>We believe it's needed to enforce our legal agreements or that is legally required</li>
                  <li>We believe it's needed to detect, prevent or address fraud, security or technical issues</li>
                </ul>

                <h3>Sharing of Data</h3>
                <p>
                  We don't share your personal information with third parties. Aggregated, anonymized data is
                  periodically transmitted to external services to help us improve the Website and service.
                </p>
                <p>
                  We may allow third parties to provide analytics services. These third parties may use cookies, web
                  beacons and other technologies to collect information about your use of the services and other
                  websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and
                  conversion information.
                </p>

                <h3>Security</h3>
                <p>
                  We take reasonable steps to protect personally identifiable information from loss, misuse, and
                  unauthorized access, disclosure, alteration, or destruction. But, you should keep in mind that no
                  Internet transmission is ever completely secure or error-free. In particular, email sent to or from
                  the Sites may not be secure.
                </p>

                <h3>About Children</h3>
                <p>
                  The Website is not intended for children under the age of 13. We do not knowingly collect personally
                  identifiable information via the Website from visitors in this age group.
                </p>

                <h3>Changes to the Privacy Policy</h3>
                <p>
                  We may amend this Privacy Policy from time to time. Use of information we collect now is subject to
                  the Privacy Policy in effect at the time such information is used.
                </p>
                <p>
                  If we make major changes in the way we collect or use information, we will notify you by posting an
                  announcement on the Website.
                </p>
              </Segment>
            </Container>
          </>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default PrivacyPolicyPage;
export { PrivacyPolicyPage };
