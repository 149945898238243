import React, { Fragment, PureComponent } from 'react';
// import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { HIGHLIGHTED_RUNS_QUERY } from '../../apollo';
import { RunTableCol, SortDir } from '../../types';
import { WelcomeSegment } from '../HeaderSegments';
import RunTable from '../RunTable';
import Ad from '../Shared/Ad';
// import { InfoContainer } from '../Shared/InfoContainer';
import { StandardHelmet } from '../Shared/StandardHelmet';

const cols: RunTableCol[] = [
  RunTableCol.Vods,
  RunTableCol.Game,
  RunTableCol.Platform,
  RunTableCol.Category,
  RunTableCol.Event,
  RunTableCol.Runners,
  RunTableCol.Time,
];

const title = 'GDQ VODs';
const description =
  'Search and browse through every AGDQ and SGDQ speedrun by event, game, runner, platform, genre, or category.';

class HomePage extends PureComponent {
  public render() {
    return (
      <Fragment>
        <StandardHelmet title={title} description={description} />
        {/*
        <InfoContainer header="AGDQ 2023 is on now!">
          <p>
            Go to the{' '}
            <a href="https://gamesdonequick.com/" target="_blank" rel="noopener noreferrer">
              Games Done Quick
            </a>{' '}
            site to watch and donate. VODs will be updated on the <Link to="/event/agdq-2023/">AGDQ 2023 page</Link>{' '}
            throughout the event but go to the{' '}
            <a href="https://www.reddit.com/r/speedrun/comments/106tyto/agdq_vod_thread_2023/">VOD thread</a> on{' '}
            <a href="https://www.reddit.com/r/speedrun/comments/106tyto/agdq_vod_thread_2023/">r/speedrun</a> for the{' '}
            most up-to-date links.
          </p>
        </InfoContainer>
        */}
        <WelcomeSegment />
        <Container>
          <Ad key="HomePage" />
          <RunTable
            key="highlights"
            query={HIGHLIGHTED_RUNS_QUERY}
            defaultSortCol={RunTableCol.Event}
            defaultSortDir={SortDir.Descending}
            cols={cols}
          />
        </Container>
      </Fragment>
    );
  }
}

export default HomePage;
export { HomePage };
