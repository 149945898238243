import React, { PureComponent } from 'react';
import { Card, Container } from 'semantic-ui-react';
import { LoadingCard } from '../Cards';

interface Props {
  numPlaceholders?: number;
}

class LoadingCards extends PureComponent<Props> {
  public render() {
    const { numPlaceholders } = this.props;

    return (
      <Container>
        <Card.Group centered={true}>
          {[...Array(numPlaceholders || 12)].map((_, i) => (
            <LoadingCard key={`loading-card-${i}`} />
          ))}
        </Card.Group>
      </Container>
    );
  }
}

export default LoadingCards;
export { LoadingCards };
