import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { EVENTS_QUERY } from '../../apollo';
import { EventQueryData } from '../../types';
import { sortEvents } from '../../utils';
import { EventCard } from '../Cards';
import StandardHelmet from '../Shared/StandardHelmet';
import CardIndexPage from './CardIndexPage';

const title = 'GDQ VODs · Events';
const description =
  'Browse through the VODs for all Games Done Quick events, including AGDQ, SGDQ, GDQx, and special events.';

class EventsPage extends PureComponent {
  public render() {
    return (
      <Query<EventQueryData> query={EVENTS_QUERY}>
        {({ data, error, loading }: QueryResult<EventQueryData>) => {
          const events = this.getEvents(data);
          const loadingFirstTime = loading && events.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <CardIndexPage adKey="EventsPage" loading={loadingFirstTime} error={!!error} numPlaceholders={18}>
                {events
                  .slice()
                  .sort(sortEvents)
                  .map((event, i) => (
                    <EventCard key={i} event={event} />
                  ))}
              </CardIndexPage>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getEvents(data?: EventQueryData) {
    return (data && data.events) || [];
  }
}

export default EventsPage;
export { EventsPage };
