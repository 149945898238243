/* tslint:disable:max-classes-per-file */

import React, { Fragment, PureComponent, ReactNode } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { IndexedVod, Run, Vod, VodSource } from '../../types';
import { vodHref } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';

interface MultiPartLinkProps {
  run: Run;
  vod: Vod;
  vodIndex: number;
  videoIndex: number;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

class MultiPartLink extends PureComponent<MultiPartLinkProps> {
  public render() {
    const { vod, videoIndex } = this.props;

    return (
      <Fragment key={videoIndex}>
        {!!videoIndex && ' '}
        <a
          href={vodHref(vod)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onClick}
          aria-label={`VOD Part ${videoIndex + 1}`}
        >
          {`${videoIndex + 1}`}
        </a>
      </Fragment>
    );
  }

  private onClick = (e: React.MouseEvent) => {
    const { run, vodIndex, videoIndex, setPlayingVod } = this.props;
    e.preventDefault();
    setPlayingVod(run.id, vodIndex, videoIndex);
  };
}

interface MultiPartLinkPopupProps {
  run: Run;
  vod: Vod;
  vodIndex: number;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
  trigger: ReactNode;
}

class MultiPartLinkPopup extends PureComponent<MultiPartLinkPopupProps> {
  public render() {
    const { run, vod, vodIndex, setPlayingVod, trigger } = this.props;
    return (
      <Popup trigger={trigger} position="bottom center" size="small" hoverable={true}>
        <List>
          <List.Item>
            Part{' '}
            {vod.videoIds.map((_, i) => (
              <MultiPartLink
                key={i}
                run={run}
                vod={vod}
                vodIndex={vodIndex}
                videoIndex={i}
                setPlayingVod={setPlayingVod}
              />
            ))}
          </List.Item>
        </List>
      </Popup>
    );
  }
}

interface PrimaryLinkProps {
  run: Run;
  vod: Vod;
  vodIndex: number;
  showPopup: boolean;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

class VideoIconLink extends PureComponent<PrimaryLinkProps> {
  public render() {
    const { vod } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <a
            href={vodHref(vod)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onClick}
            aria-label={vod.source === VodSource.YouTube ? 'YouTube VOD' : 'Twitch VOD'}
          >
            <Icon
              name={vod.source === VodSource.YouTube ? 'youtube' : 'twitch'}
              color={vod.source === VodSource.YouTube ? 'red' : 'violet'}
              size="large"
              link={true}
              inverted={darkMode}
            />
          </a>
        )}
      </GlobalContext.Consumer>
    );
  }

  private onClick = (e: React.MouseEvent) => {
    const { run, vodIndex, setPlayingVod } = this.props;
    e.preventDefault();
    setPlayingVod(run.id, vodIndex);
  };
}

class PrimaryLink extends PureComponent<PrimaryLinkProps> {
  public render() {
    const { run, vod, vodIndex, setPlayingVod, showPopup } = this.props;
    const iconLink = (
      <VideoIconLink run={run} vod={vod} vodIndex={vodIndex} setPlayingVod={setPlayingVod} showPopup={showPopup} />
    );

    if (!showPopup || vod.videoIds.length === 1) {
      return iconLink;
    }

    return (
      <MultiPartLinkPopup
        trigger={<span>{iconLink}</span>}
        run={run}
        vod={vod}
        vodIndex={vodIndex}
        setPlayingVod={setPlayingVod}
      />
    );
  }
}

interface SecondaryLinkProps {
  run: Run;
  vod: Vod;
  vodIndex: number;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

class SecondaryLink extends PureComponent<SecondaryLinkProps> {
  public render() {
    const { vod } = this.props;
    return (
      <a href={vodHref(vod)} target="_blank" rel="noopener noreferrer" onClick={this.onClick}>
        {`Extra ${vod.source === VodSource.YouTube ? 'YouTube' : 'Twitch'} VOD${vod.info ? ` (${vod.info})` : ''}`}
      </a>
    );
  }

  private onClick = (e: React.MouseEvent) => {
    const { run, vodIndex, setPlayingVod } = this.props;
    e.preventDefault();
    setPlayingVod(run.id, vodIndex);
  };
}

interface SecondaryLinksProps {
  run: Run;
  vods: IndexedVod[];
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

class SecondaryLinks extends PureComponent<SecondaryLinksProps> {
  public render() {
    const { run, vods, setPlayingVod } = this.props;
    if (vods.length === 0) {
      return null;
    }

    return (
      <Popup trigger={<Icon name="dropdown" link={true} />} position="bottom center" size="small" hoverable={true}>
        <List>
          {vods.map(indexedVod => (
            <List.Item key={indexedVod.index}>
              <SecondaryLink run={run} vod={indexedVod.vod} vodIndex={indexedVod.index} setPlayingVod={setPlayingVod} />
            </List.Item>
          ))}
        </List>
      </Popup>
    );
  }
}

interface VodLinksProps {
  run: Run;
  showPopup: boolean;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

export class VodLinks extends PureComponent<VodLinksProps> {
  public render() {
    const { run, setPlayingVod, showPopup } = this.props;
    const { primaryYoutube, primaryTwitch, secondary } = this.separateVods();
    return (
      <Fragment>
        {primaryYoutube && (
          <PrimaryLink
            run={run}
            vod={primaryYoutube.vod}
            vodIndex={primaryYoutube.index}
            showPopup={showPopup}
            setPlayingVod={setPlayingVod}
          />
        )}
        {primaryTwitch && (
          <PrimaryLink
            run={run}
            vod={primaryTwitch.vod}
            vodIndex={primaryTwitch.index}
            showPopup={showPopup}
            setPlayingVod={setPlayingVod}
          />
        )}
        {secondary.length > 0 && <SecondaryLinks run={run} vods={secondary} setPlayingVod={setPlayingVod} />}
      </Fragment>
    );
  }

  private separateVods(): {
    primaryYoutube: IndexedVod | null;
    primaryTwitch: IndexedVod | null;
    secondary: IndexedVod[];
  } {
    let primaryYoutube: IndexedVod | null = null;
    let primaryTwitch: IndexedVod | null = null;
    const secondary: IndexedVod[] = [];

    const { run } = this.props;
    run.vods.forEach((vod, index) => {
      if (!primaryYoutube && vod.source === VodSource.YouTube && vod.isPrimary) {
        primaryYoutube = { vod, index };
      } else if (!primaryTwitch && vod.source === VodSource.Twitch && vod.isPrimary) {
        primaryTwitch = { vod, index };
      } else if (!vod.isPrimary) {
        secondary.push({ vod, index });
      }
    });

    return { primaryYoutube, primaryTwitch, secondary };
  }
}
