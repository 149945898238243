import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import { GAMES_QUERY } from '../../apollo';
import { GameQueryData } from '../../types';
import { GamesTable } from '../IndexTables';
import Ad from '../Shared/Ad';
import StandardHelmet from '../Shared/StandardHelmet';

const title = 'GDQ VODs · Games';
const description =
  'All the games that have been run at a Games Done Quick event, sortable by name, year, number of runs, etc.';

class GamesPage extends PureComponent {
  public render() {
    return (
      <Query<GameQueryData> query={GAMES_QUERY}>
        {({ data, error, loading }: QueryResult<GameQueryData>) => {
          const games = this.getGames(data);
          const loadingFirstTime = loading && games.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <Container>
                <Ad key="GamesPage" />
                <GamesTable loading={loadingFirstTime} error={!!error} games={games} />
              </Container>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getGames(data?: GameQueryData) {
    return (data && data.games) || [];
  }
}

export default GamesPage;
export { GamesPage };
