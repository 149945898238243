import React, { PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RunQueryData, RunTableCol, SortDir } from '../../types';
import ErrorContainer from '../Shared/ErrorContainer';
import RunTableContainer from './RunTableContainer';

interface Props {
  query: any;
  queryVars?: any;
  defaultSortCol: RunTableCol;
  defaultSortDir: SortDir;
  cols: RunTableCol[];
}

class RunTableQuery extends PureComponent<Props> {
  public render() {
    const { query, queryVars } = this.props;
    return (
      <Query<RunQueryData> query={query} variables={queryVars}>
        {({ data, error, loading }: QueryResult<RunQueryData>) => {
          if (error) {
            return <ErrorContainer />;
          }

          const runs = data && data.runs ? data.runs : [];
          if (runs.length === 0 && !loading) {
            return <ErrorContainer />;
          }

          const { defaultSortCol, defaultSortDir, cols } = this.props;
          const loadingFirstTime = loading && runs.length === 0;

          return (
            <RunTableContainer
              defaultSortCol={defaultSortCol}
              defaultSortDir={defaultSortDir}
              cols={cols}
              runs={runs}
              loading={loadingFirstTime}
            />
          );
        }}
      </Query>
    );
  }
}

export default RunTableQuery;
export { RunTableQuery };
