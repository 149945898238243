import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { RunTableCol, SortDir } from '../../types';
import { sortDirToString } from '../../utils';
import { HeaderCell } from './HeaderCells';

interface Props {
  cols: RunTableCol[];
  sortedCol: RunTableCol;
  sortedDir: SortDir;
  handleSort: (col: RunTableCol) => void;
  playRandomRun: () => void;
}

class RunTableHeaderRow extends PureComponent<Props> {
  public render() {
    const { cols, playRandomRun, handleSort } = this.props;
    return (
      <Table.Row>
        {cols.map(c => (
          <HeaderCell
            key={c.toString()}
            col={c}
            handleSort={handleSort}
            playRandomRun={playRandomRun}
            sortable={c !== RunTableCol.Vods}
            sorted={this.getSorted(c)}
          />
        ))}
      </Table.Row>
    );
  }

  private getSorted(col: RunTableCol) {
    const { sortedCol, sortedDir } = this.props;
    return sortDirToString(sortedCol === col ? sortedDir : undefined);
  }
}

export { RunTableHeaderRow };
