import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Icon, List, Menu as SemanticMenu, Popup } from 'semantic-ui-react';

import { DesktopMenuSection } from './menuItems';

import './PopupMenu.css';

interface State {
  isOpen: boolean;
}

class PopupMenu extends PureComponent<DesktopMenuSection, State> {
  public state = {
    isOpen: false,
  };

  public render() {
    const { isOpen } = this.state;
    const { title, to, width, numCols, columns, ...rest } = this.props;
    return (
      <Popup
        trigger={
          <SemanticMenu.Item as={Link} to={to}>
            {title}
            <Icon name="dropdown" />
          </SemanticMenu.Item>
        }
        position="bottom center"
        className="no-pointer"
        hoverable={true}
        style={{ maxWidth: width, width }}
        open={isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        {...rest}
      >
        <Grid textAlign="center" columns={numCols}>
          <Grid.Row>
            {columns.map((column, colIdx) => (
              <Grid.Column key={colIdx}>
                {column.header && <Header as="h4">{column.header}</Header>}
                <List link={true}>
                  {column.items.map((item, itemIdx) => (
                    <List.Item key={itemIdx} as={Link} to={item.to} onClick={this.handleClose}>
                      {item.name}
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Popup>
    );
  }

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleClose = () => {
    this.setState({ isOpen: false });
  };
}

export default PopupMenu;
export { PopupMenu };
