import React, { PureComponent } from 'react';
import { Responsive } from 'semantic-ui-react';
import { dropdownMenuBreakpoint } from '../../utils';
import { PopupMenu } from './PopupMenu';

import { DesktopMenuSection } from './menuItems';

import './Menu.css';

interface Props {
  section: DesktopMenuSection;
}

class MenuSection extends PureComponent<Props> {
  public render() {
    const { section } = this.props;
    const { title, to, width, columns, numCols } = section;

    return (
      <Responsive
        as={PopupMenu}
        minWidth={dropdownMenuBreakpoint}
        title={title}
        to={to}
        width={width}
        columns={columns}
        numCols={numCols}
      />
    );
  }
}

export default MenuSection;
export { MenuSection };
