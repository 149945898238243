import React, { PureComponent, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';
import { Segment, SegmentProps } from 'semantic-ui-react';
import { loaderPrimaryColour, loaderSecondaryColour } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';

interface Props extends SegmentProps {
  loading: boolean;
  children: ReactNode;
}

export class LoadingSegment extends PureComponent<Props> {
  public render() {
    const { loading, children, ...rest } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Segment {...rest} inverted={darkMode}>
            {loading && (
              <ContentLoader
                height={110}
                width={660}
                primaryColor={loaderPrimaryColour(darkMode)}
                secondaryColor={loaderSecondaryColour(darkMode)}
              >
                <rect x="130" y="0" rx="3" ry="3" height="25" width="400" />
                <rect x="50" y="45" rx="3" ry="3" height="15" width="560" />
                <rect x="30" y="70" rx="3" ry="3" height="15" width="600" />
                <rect x="200" y="95" rx="3" ry="3" height="15" width="260" />
              </ContentLoader>
            )}
            {!loading && children}
          </Segment>
        )}
      </GlobalContext.Consumer>
    );
  }
}
