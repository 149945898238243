import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { GlobalContext } from '../App/GlobalContext';

import './Footer.css';

class Footer extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ darkMode, toggleDarkMode, clearWatchedRuns, hideRunTimes, toggleHideRunTimes }) => (
          <footer>
            <Container textAlign="center">
              Send questions/comments to <a href="mailto:email.gdqvods@gmail.com">email.gdqvods@gmail.com</a>
              {' · '}
              <button onClick={clearWatchedRuns} className="link">
                Reset Watched Status
              </button>
              {' · '}
              <button onClick={toggleDarkMode} className="link">
                {darkMode ? 'Disable' : 'Enable'} Dark Mode
              </button>
              {' · '}
              <button onClick={toggleHideRunTimes} className="link">
                {hideRunTimes ? 'Show' : 'Hide'} Run Times
              </button>
              {' · '}
              <Link to="/privacy">Privacy Policy</Link>
              {' · '}
              <Link to="/terms">Terms of Service</Link>
            </Container>
          </footer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default Footer;
export { Footer };
