import React, { Fragment, PureComponent } from 'react';
import { Container, Header, Icon } from 'semantic-ui-react';

import { Query, QueryResult } from 'react-apollo';
import { RUNNER_QUERY } from '../../apollo';
import { Runner, RunnerQueryData, RunnerQueryVars } from '../../types';
import { facebookUserHref, twitchUserHref, twitterUserHref, youtubeUserHref } from '../../utils';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  runnerId: string;
}

class RunnerSegment extends PureComponent<Props> {
  public render() {
    const { runnerId } = this.props;
    return (
      <Query<RunnerQueryData, RunnerQueryVars> query={RUNNER_QUERY} variables={{ runner: runnerId }}>
        {({ data, error, loading }: QueryResult<RunnerQueryData, RunnerQueryVars>) => {
          const runner = this.getRunner(data);
          const loadingFirstTime = loading && !runner;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && runner && (
                  <Fragment>
                    <StandardHelmet title={this.getTitle(runner)} description={this.getDescription(runnerId, runner)} />
                    <Header>{runner.name}</Header>
                    <Markdown markdown={`${runner.description || ''}\n\n${this.getDescription(runnerId, runner)}`} />
                    {(runner.website || runner.twitch || runner.twitter || runner.youtube || runner.facebook) && (
                      <>
                        <p />
                        <p>
                          {runner.name} can be found at:
                          <br />
                          {runner.website && (
                            <Fragment>
                              <a href={runner.website} rel="noopener noreferrer" target="_blank">
                                <Icon name="home" size="big" circular={true} link={true} />
                              </a>{' '}
                            </Fragment>
                          )}
                          {runner.twitch && (
                            <Fragment>
                              <a href={twitchUserHref(runner.twitch)} rel="noopener noreferrer" target="_blank">
                                <Icon name="twitch" size="big" circular={true} link={true} />
                              </a>{' '}
                            </Fragment>
                          )}
                          {runner.twitter && (
                            <Fragment>
                              <a href={twitterUserHref(runner.twitter)} rel="noopener noreferrer" target="_blank">
                                <Icon name="twitter" size="big" circular={true} link={true} />
                              </a>{' '}
                            </Fragment>
                          )}
                          {runner.youtube && (
                            <Fragment>
                              <a href={youtubeUserHref(runner.youtube)} rel="noopener noreferrer" target="_blank">
                                <Icon name="youtube" size="big" circular={true} link={true} />
                              </a>{' '}
                            </Fragment>
                          )}
                          {runner.facebook && (
                            <a href={facebookUserHref(runner.facebook)} rel="noopener noreferrer" target="_blank">
                              <Icon name="facebook" size="big" circular={true} link={true} />
                            </a>
                          )}
                        </p>
                      </>
                    )}
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(runner?: Runner) {
    const title = 'GDQ VODs';
    return runner ? `${title} · ${runner.name}` : title;
  }

  private getDescription(runnerId: string, runner?: Runner) {
    const nameToUse = runner ? runner.name : runnerId;
    return `Watch the VODs of ${nameToUse}'s runs here and browse through related games, runners, and special categories.`;
  }

  private getRunner(runnerData?: RunnerQueryData) {
    return runnerData && runnerData.runners ? runnerData.runners[0] : undefined;
  }
}

export default RunnerSegment;
export { RunnerSegment };
