import React, { PureComponent } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { RunTableCol } from '../../types';
import { GlobalContext } from '../App/GlobalContext';
import LoadingTableCell from '../Shared/LoadingTableCell';

export interface LoadingCellProps {
  col: RunTableCol;
}

export class LoadingCell extends PureComponent<LoadingCellProps> {
  public render() {
    const { col } = this.props;
    switch (col) {
      case RunTableCol.StartTime:
        return <LoadingTableCell loaderWidth={50} />;
      case RunTableCol.Game:
        return <LoadingTableCell loaderWidth={50} randomRange={50} />;
      case RunTableCol.GameNoYear:
        return <LoadingTableCell loaderWidth={30} randomRange={50} />;
      case RunTableCol.Year:
        return <LoadingTableCell loaderWidth={40} />;
      case RunTableCol.Platform:
        return <LoadingTableCell loaderWidth={60} randomRange={20} />;
      case RunTableCol.Category:
        return <LoadingTableCell loaderWidth={60} randomRange={20} />;
      case RunTableCol.Genre:
        return <LoadingTableCell loaderWidth={60} randomRange={20} />;
      case RunTableCol.Event:
        return <LoadingTableCell loaderWidth={40} />;
      case RunTableCol.Runners:
        return <LoadingTableCell loaderWidth={20} randomRange={80} />;
      case RunTableCol.Time:
        return <LoadingTableCell loaderWidth={40} />;
      case RunTableCol.Vods:
        return (
          <GlobalContext.Consumer>
            {({ darkMode }) => (
              <Table.Cell textAlign="center" style={{ whiteSpace: 'nowrap' }}>
                <Icon name="youtube" color="red" size="large" inverted={darkMode} disabled={true} />
                <Icon name="twitch" color="violet" size="large" inverted={darkMode} disabled={true} />
              </Table.Cell>
            )}
          </GlobalContext.Consumer>
        );
      case RunTableCol.WorldRecord:
        return <LoadingTableCell loaderWidth={20} />;
      case RunTableCol.Handicapped:
        return <LoadingTableCell loaderWidth={20} />;
      case RunTableCol.DevCommentary:
        return <LoadingTableCell loaderWidth={20} />;
      case RunTableCol.Race:
        return <LoadingTableCell loaderWidth={20} />;
      case RunTableCol.Tas:
        return <LoadingTableCell loaderWidth={20} />;
      default:
        return null;
    }
  }
}

export default LoadingCell;
