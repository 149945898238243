import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { Run, RunTableCol } from '../../types';
import { BodyCell } from './BodyCells';

interface Props {
  run: Run;
  cols: RunTableCol[];
  isWatched: boolean;
  darkMode?: boolean;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
}

class RunTableStandardRow extends PureComponent<Props> {
  public render() {
    const { run, cols, isWatched, setPlayingVod, darkMode } = this.props;
    const rowStyles = { backgroundColor: isWatched ? (darkMode ? 'rgba(0, 0, 0, 0.2)' : '#f9fafb') : undefined };

    return (
      <Table.Row style={rowStyles}>
        {cols.map(c => (
          <BodyCell key={c.toString()} col={c} run={run} setPlayingVod={setPlayingVod} />
        ))}
      </Table.Row>
    );
  }
}

export { RunTableStandardRow };
