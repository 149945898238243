import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Container } from 'semantic-ui-react';
import { EVENT_RUNS_QUERY } from '../../apollo';
import { RunTableCol, SortDir } from '../../types';
import { EventSegment } from '../HeaderSegments';
import RunTable from '../RunTable';
import Ad from '../Shared/Ad';

const cols: RunTableCol[] = [
  RunTableCol.Vods,
  RunTableCol.StartTime,
  RunTableCol.Game,
  RunTableCol.Platform,
  RunTableCol.Category,
  RunTableCol.Runners,
  RunTableCol.Time,
];

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

class EventPage extends PureComponent<Props> {
  public render() {
    const { match } = this.props;
    return (
      <Fragment>
        <EventSegment eventId={match.params.id} />
        <Container>
          <Ad key="EventPage" />
          <RunTable
            key={`event-${match.params.id}`}
            query={EVENT_RUNS_QUERY}
            queryVars={{ event: match.params.id }}
            defaultSortCol={RunTableCol.StartTime}
            defaultSortDir={SortDir.Ascending}
            cols={cols}
          />
        </Container>
      </Fragment>
    );
  }
}

export default EventPage;
export { EventPage };
