import React, { Fragment, PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { Query, QueryResult } from 'react-apollo';
import { PLATFORM_QUERY } from '../../apollo';
import { Platform, PlatformQueryData, PlatformQueryVars } from '../../types';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  platformId: string;
}

class PlatformSegment extends PureComponent<Props> {
  public render() {
    const { platformId } = this.props;
    return (
      <Query<PlatformQueryData, PlatformQueryVars> query={PLATFORM_QUERY} variables={{ platform: platformId }}>
        {({ data, error, loading }: QueryResult<PlatformQueryData, PlatformQueryVars>) => {
          const platform = this.getPlatform(data);
          const loadingFirstTime = loading && !platform;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <StandardHelmet title={this.getTitle(platform)} description={this.getDescription(platformId, platform)} />
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && platform && (
                  <Fragment>
                    <Header>{platform.name}</Header>
                    <Markdown markdown={`${platform.description}\n\n${this.getDescription(platformId, platform)}`} />
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(platform?: Platform) {
    const title = 'GDQ VODs';
    return platform ? `${title} · ${platform.name}` : title;
  }

  private getDescription(platformId: string, platform?: Platform) {
    const nameToUse = platform ? platform.name : platformId;
    return `Watch the VODs of all ${nameToUse} game runs here and browse through related games, runners, and special categories.`;
  }

  private getPlatform(platformData?: PlatformQueryData) {
    return platformData && platformData.platforms ? platformData.platforms[0] : undefined;
  }
}

export default PlatformSegment;
export { PlatformSegment };
