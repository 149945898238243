import memoizeOne from 'memoize-one';
import React, { PureComponent } from 'react';
import { PlayingVod, Run, RunTableCol, SortDir } from '../../types';
import { getRunSortFunc, RunSortDefaults } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { RunTableComponent } from './RunTableComponent';

interface Props {
  defaultSortCol: RunTableCol;
  defaultSortDir: SortDir;
  cols: RunTableCol[];
  runs: Run[];
  loading: boolean;
}

interface State {
  page: number;
  playingVod?: PlayingVod;
  recentlyPlayed: Set<string>;
  sortCol: RunTableCol;
  sortDir: SortDir;
}

export const PAGE_SIZE = 200;

class RunTableContainer extends PureComponent<Props, State> {
  public static contextType = GlobalContext;

  public state = {
    page: 0,
    playingVod: undefined,
    recentlyPlayed: new Set(),
    sortCol: this.props.defaultSortCol,
    sortDir: this.props.defaultSortDir,
  };

  private memoizedSortedRuns = memoizeOne(
    (runs, sortCol, sortDir) => {
      const sortFn = getRunSortFunc(sortCol, sortDir);
      return runs.sort(sortFn);
    },
    (newArgs, lastArgs) => newArgs[1] === lastArgs[1] && newArgs[2] === lastArgs[2]
  );

  public render() {
    const { cols, runs, loading } = this.props;
    const { sortCol, sortDir, playingVod, page } = this.state;

    const sortedRuns = loading ? [] : this.memoizedSortedRuns(runs, sortCol, sortDir);
    const randomRun = this.getRandomRun();

    const start = page * PAGE_SIZE;
    const runPage = sortedRuns.slice(start, start + PAGE_SIZE);
    const totalPages = Math.ceil(sortedRuns.length / PAGE_SIZE);

    return (
      <RunTableComponent
        cols={cols}
        runPage={runPage}
        allRuns={sortedRuns}
        sortedCol={sortCol}
        sortedDir={sortDir}
        playingVod={playingVod}
        randomRun={randomRun}
        handleSort={this.handleSort}
        playRun={this.playRun}
        playRandomRun={this.playRandomRun}
        setPlayingVod={this.setPlayingVod}
        closePlayingVod={this.closePlayingVod}
        autoplayRun={this.autoplayRun}
        loading={loading}
        page={page}
        totalPages={totalPages}
        handlePageChange={this.handlePageChange}
      />
    );
  }

  private handleSort = (col: RunTableCol) => {
    const { sortCol, sortDir } = this.state;

    const newSortDir =
      sortCol === col ? (sortDir === SortDir.Ascending ? SortDir.Descending : SortDir.Ascending) : RunSortDefaults[col];

    // Clear playing VOD on sort - i'm not sure how to continue the video from where it was up to on re-render
    this.setState({ playingVod: undefined, sortCol: col, sortDir: newSortDir });
  };

  private playRun = (run: Run) => {
    this.setPlayingVod(run.id);
  };

  private getRandomRun() {
    const { runs } = this.props;
    const { recentlyPlayed } = this.state;
    const notRecentlyPlayed = runs.filter(r => r.vods.length > 0 && !recentlyPlayed.has(r.id));
    if (notRecentlyPlayed.length > 0) {
      return notRecentlyPlayed[Math.floor(Math.random() * notRecentlyPlayed.length)];
    }
  }

  private playRandomRun = () => {
    const randomRun = this.getRandomRun();
    if (randomRun) {
      this.setPlayingVod(randomRun.id);
    }
  };

  private setPlayingVod = (runId: string, vodIndex?: number, videoIndex?: number) => {
    const { addWatchedRun } = this.context;
    const { recentlyPlayed } = this.state;
    const { loading } = this.props;

    if (loading) {
      return;
    }

    recentlyPlayed.add(runId);
    const page = this.getRunPage(runId);

    this.setState({
      page,
      playingVod: { runId, vodIndex: vodIndex || 0, videoIndex: videoIndex || 0 },
      recentlyPlayed,
    });

    addWatchedRun(runId);
  };

  private getRunPage = (runId: string) => {
    const { sortCol, sortDir } = this.state;
    const { runs } = this.props;

    const sortedRuns = this.memoizedSortedRuns(runs, sortCol, sortDir);
    const runIndex = sortedRuns.findIndex((run: Run) => run.id === runId);
    return Math.floor(runIndex / PAGE_SIZE);
  };

  private closePlayingVod = () => {
    this.setState({ playingVod: undefined, recentlyPlayed: new Set() });
  };

  private autoplayRun = (run: Run) => {
    const { isAutoplayOn, isRandomOn } = this.context;
    if (isAutoplayOn) {
      if (isRandomOn) {
        this.playRandomRun();
      } else if (run) {
        this.playRun(run);
      }
    }
  };

  private handlePageChange = (page: number) => {
    this.setState({ page, playingVod: undefined });
  };
}

export default RunTableContainer;
export { RunTableContainer };
