import React, { PureComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { Table } from 'semantic-ui-react';
import { loaderPrimaryColour, loaderSecondaryColour } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';

interface Props {
  cellWidth?: string;
  loaderWidth: number;
  randomRange?: number;
}

class LoadingTableCell extends PureComponent<Props> {
  public render() {
    const { cellWidth, loaderWidth, randomRange } = this.props;
    const width = loaderWidth + Math.floor(Math.random() * (randomRange || 0));
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Table.Cell style={cellWidth ? { width: cellWidth } : undefined}>
            <div style={{ height: 20, width }}>
              <ContentLoader
                height={20}
                width={width}
                primaryColor={loaderPrimaryColour(darkMode)}
                secondaryColor={loaderSecondaryColour(darkMode)}
              >
                <rect x="0" y="3" rx="3" ry="3" height="14" width={width} />
              </ContentLoader>
            </div>
          </Table.Cell>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default LoadingTableCell;
export { LoadingTableCell };
