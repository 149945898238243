import React, { PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

interface LinkProps {
  href: string;
  children: any;
}

function RouterLink(props: LinkProps) {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href}>{props.children}</a>
  ) : (
    <Link to={props.href}>{props.children}</Link>
  );
}

interface Props {
  markdown: string;
}

class Markdown extends PureComponent<Props> {
  public render() {
    const { markdown } = this.props;
    return <ReactMarkdown source={markdown} renderers={{ link: RouterLink }} />;
  }
}

export default Markdown;
export { Markdown };
