import moment from 'moment-timezone';
import { Event, Game, Genre, SortDir, Vod, VodSource } from '../types';

const humanDate = (apiDate: string) => {
  return moment.utc(apiDate).format('Do MMM YYYY');
};

export const humanDateTime = (seconds: number, timezone: string) => {
  return moment(seconds * 1000)
    .tz(timezone)
    .format('ddd, MMM D h:mm A');
};

const money = (amount: number) => {
  return `$${Math.round(amount).toLocaleString()}`;
};

export const eventDescription = (event: Event, short: boolean = false) => {
  let generatedDesc = `${event.shortName} was held from ${humanDate(event.startDate)} to ${humanDate(event.endDate)}`;
  if (event.totalRaised) {
    generatedDesc += ` and it raised ${money(event.totalRaised)}`;
    if (event.charity) {
      generatedDesc += ` for ${event.charity}`;
    }
  }
  if (!short && event.extraInfo) {
    generatedDesc += `. ${event.extraInfo}`;
  } else {
    generatedDesc += '.';
  }

  const description = event.description || generatedDesc;
  const shortDescription = event.shortDescription || description;

  return short ? shortDescription : description;
};

export const gameDescription = (game: Game) => {
  let description = game.description;

  if (!description) {
    if (game.platform.name === 'Arcade') {
      description = `${game.name} is an arcade ${game.genre.name} game that was released in ${game.year}.`;
    } else {
      description = `${game.name} is a ${game.genre.name} game that was released on the ${game.platform.name} in ${game.year}.`;
    }
  }

  if (game.franchiseCategory) {
    description += ` It is part of the [${game.franchiseCategory.name}](/category/${game.franchiseCategory.id}).`;
  }

  return description;
};

export const genreDescription = (genre: Genre, short: boolean = false) => {
  if (short && genre.shortDescription) {
    return genre.shortDescription;
  }
  return genre.description;
};

export const vodHref = (vod: Vod, videoIndex: number = 0) => {
  return vod.source === VodSource.YouTube ? youtubeVodHref(vod, videoIndex) : twitchVodHref(vod, videoIndex);
};

const youtubeVodHref = (vod: Vod, videoIndex: number = 0) => {
  const params = [`v=${vod.videoIds[videoIndex]}`];
  if (vod.start && videoIndex === 0) {
    params.push(`time_continue=${vod.start}`);
  }
  return `//www.youtube.com/watch?${params.join('&')}`;
};

const twitchVodHref = (vod: Vod, videoIndex: number = 0) => {
  const videoId = vod.videoIds[videoIndex].substring(1);
  const params = vod.start && videoIndex === 0 ? `?t=${vod.start}` : '';
  return `//twitch.tv/videos/${videoId}${params}`;
};

export const twitchUserHref = (twitchId: string) => {
  return `//www.twitch.tv/${twitchId}`;
};

export const twitterUserHref = (twitterId: string) => {
  return `//www.twitter.com/${twitterId}`;
};

export const youtubeUserHref = (youtubeId: string) => {
  return `//www.youtube.com/${youtubeId}`;
};

export const youtubeUserName = (youtubeId: string) => {
  if (youtubeId.startsWith('channel/')) {
    return '';
  } else if (youtubeId.startsWith('user/')) {
    return youtubeId.substr('user/'.length);
  } else {
    return youtubeId;
  }
};

export const facebookUserHref = (facebookId: string) => {
  return `//www.facebook.com/${facebookId}`;
};

export const siteCategoryBool = (isTrue: boolean) => {
  return isTrue ? 'Yes' : 'No';
};

export function sortDirToString(sortDir?: SortDir) {
  if (sortDir === undefined) {
    return undefined;
  }
  return sortDir === SortDir.Ascending ? 'ascending' : 'descending';
}

export function cardStyle(darkMode: boolean) {
  return darkMode ? { border: 'none', boxShadow: 'none', background: '#1b1c1d' } : undefined;
}

export function backgroundColour(darkMode: boolean) {
  return darkMode ? { background: '#1b1c1d' } : undefined;
}

export function textColour(darkMode: boolean) {
  return darkMode ? { color: 'rgba(255,255,255,.9)' } : undefined;
}

export function loaderPrimaryColour(darkMode: boolean) {
  return darkMode ? '#222' : '#f3f3f3';
}

export function loaderSecondaryColour(darkMode: boolean) {
  return darkMode ? '#555' : '#ecebeb';
}
