import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description: string;
}

class StandardHelmet extends PureComponent<Props> {
  public render() {
    const { title, description } = this.props;
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    );
  }
}

export default StandardHelmet;
export { StandardHelmet };
