import React, { PureComponent } from 'react';
// @ts-ignore
import AdSense from 'react-adsense';

class Ad extends PureComponent {
  public render() {
    return (
      <AdSense.Google
        client="ca-pub-4369217296754794"
        slot="8390492082"
        style={{ display: 'block' }}
        layout="in-article"
        format="fluid"
      />
    );
  }
}

export default Ad;
export { Ad };
