import React, { Fragment, PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { Query, QueryResult } from 'react-apollo';
import { GAME_QUERY } from '../../apollo';
import { Game, GameQueryData, GameQueryVars } from '../../types';
import { gameDescription } from '../../utils';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  gameId: string;
}

class GameSegment extends PureComponent<Props> {
  public render() {
    const { gameId } = this.props;
    return (
      <Query<GameQueryData, GameQueryVars> query={GAME_QUERY} variables={{ game: gameId }}>
        {({ data, error, loading }: QueryResult<GameQueryData, GameQueryVars>) => {
          const game = this.getGame(data);
          const loadingFirstTime = loading && !game;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <StandardHelmet title={this.getTitle(game)} description={this.getDescription(gameId, game)} />
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && game && (
                  <Fragment>
                    <Header>{game.name}</Header>
                    <Markdown markdown={`${gameDescription(game)}\n\n${this.getDescription(gameId, game)}`} />
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(game?: Game) {
    const title = 'GDQ VODs';
    return game ? `${title} · ${game.name}` : title;
  }

  private getDescription(gameId: string, game?: Game) {
    const nameToUse = game ? game.name : gameId;
    return `Watch the VODs of all ${nameToUse} runs here and browse through related runners, special categories, and events.`;
  }

  private getGame(gameData?: GameQueryData) {
    return gameData && gameData.games ? gameData.games[0] : undefined;
  }
}

export default GameSegment;
export { GameSegment };
