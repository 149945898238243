import { Cookies } from 'react-cookie-consent';

export const getWithConsent = (key: string) => {
  return Cookies.get('CookieConsent') ? localStorage.getItem(key) : null;
};

export const setWithConsent = (key: string, value: string) => {
  if (Cookies.get('CookieConsent')) {
    localStorage.setItem(key, value);
  }
};
