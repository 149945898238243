import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { Runner } from '../../types';
import { facebookUserHref, twitchUserHref, twitterUserHref, youtubeUserHref } from '../../utils';

interface RunnerLinkProps {
  runner: Runner;
  showPopup: boolean;
}

export class RunnerLink extends PureComponent<RunnerLinkProps> {
  public render() {
    const { runner, showPopup } = this.props;
    if (!showPopup || (!runner.website && !runner.twitch && !runner.twitter && !runner.youtube && !runner.facebook)) {
      return <Link to={`/runner/${runner.id}`}>{runner.name}</Link>;
    }

    return (
      <Popup
        trigger={<Link to={`/runner/${runner.id}`}>{runner.name}</Link>}
        position="top center"
        size="small"
        hoverable={true}
      >
        <Fragment>
          {runner.website && (
            <a href={runner.website} rel="noopener noreferrer" target="_blank">
              <Icon name="home" circular={true} link={true} />
            </a>
          )}
          {runner.twitch && (
            <a href={twitchUserHref(runner.twitch)} rel="noopener noreferrer" target="_blank">
              <Icon name="twitch" circular={true} link={true} />
            </a>
          )}
          {runner.twitter && (
            <a href={twitterUserHref(runner.twitter)} rel="noopener noreferrer" target="_blank">
              <Icon name="twitter" circular={true} link={true} />
            </a>
          )}
          {runner.youtube && (
            <a href={youtubeUserHref(runner.youtube)} rel="noopener noreferrer" target="_blank">
              <Icon name="youtube" circular={true} link={true} />
            </a>
          )}
          {runner.facebook && (
            <a href={facebookUserHref(runner.facebook)} rel="noopener noreferrer" target="_blank">
              <Icon name="facebook" circular={true} link={true} />
            </a>
          )}
        </Fragment>
      </Popup>
    );
  }
}
