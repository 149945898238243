import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';
import { SiteCategory } from '../../types';
import { backgroundColour, cardStyle, textColour } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { Markdown } from '../Shared';

interface Props {
  siteCategory: SiteCategory;
}

class SiteCategoryCard extends PureComponent<Props> {
  public render() {
    const { siteCategory } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Card
            as={Link}
            to={siteCategory.id === 'highlights' ? '/' : `/category/${siteCategory.id}`}
            style={cardStyle(darkMode)}
          >
            <Image src={`/img/category/${siteCategory.id}.jpg`} width={290} height={290} alt={siteCategory.name} />
            <Card.Content style={backgroundColour(darkMode)}>
              <Card.Header style={textColour(darkMode)}>{siteCategory.name}</Card.Header>
              <Card.Description style={textColour(darkMode)}>
                <Markdown markdown={siteCategory.shortDescription} />
              </Card.Description>
            </Card.Content>
          </Card>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default SiteCategoryCard;
export { SiteCategoryCard };
