import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { SiteCategory } from '../../types';
import { GlobalContext } from '../App/GlobalContext';

interface SiteCategoryLabelProps {
  category: SiteCategory;
}

export class SiteCategoryLabel extends PureComponent<SiteCategoryLabelProps> {
  public render() {
    const { category } = this.props;

    let name = category.name;
    const suffix = ' Franchise';
    if (name.indexOf(suffix, name.length - suffix.length) !== -1) {
      name = name.substring(0, name.length - suffix.length);
    }

    let linkTo = `/category/${category.id}`;
    if (category.id === 'highlights') {
      linkTo = '';
    }

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Label
            as={Link}
            key={`cat-label-${category.id}`}
            to={linkTo}
            size="tiny"
            color={darkMode ? 'black' : undefined}
          >
            {name}
          </Label>
        )}
      </GlobalContext.Consumer>
    );
  }
}
