import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Game } from '../../types';
import LoadingTableCell from '../Shared/LoadingTableCell';

interface Props {
  game?: Game;
}

class GamesTableRow extends PureComponent<Props> {
  public render() {
    const { game } = this.props;
    if (!game) {
      return (
        <Table.Row>
          <LoadingTableCell cellWidth="50%" loaderWidth={100} randomRange={150} />
          <LoadingTableCell loaderWidth={40} />
          <LoadingTableCell loaderWidth={80} randomRange={20} />
          <LoadingTableCell loaderWidth={80} randomRange={20} />
          <LoadingTableCell loaderWidth={40} />
        </Table.Row>
      );
    }

    return (
      <Table.Row>
        <Table.Cell>
          <Link to={`/game/${game.id}`}>{game.name}</Link>
        </Table.Cell>
        <Table.Cell>{game.year}</Table.Cell>
        <Table.Cell>
          <Link to={`/platform/${game.platform.id}`}>{game.platform.name}</Link>
        </Table.Cell>
        <Table.Cell>
          <Link to={`/genre/${game.genre.id}`}>{game.genre.name}</Link>
        </Table.Cell>
        <Table.Cell>{game.runCount}</Table.Cell>
      </Table.Row>
    );
  }
}

export default GamesTableRow;
export { GamesTableRow };
