import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import React, { PureComponent } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { Search, SearchProps, SearchResultData, SearchResultProps } from 'semantic-ui-react';
import _ from 'underscore';
import SearchResultComponent from './SearchResultComponent';

type SearchResultType = 'RUNNER' | 'PLATFORM' | 'GAME' | 'GENRE';

interface SearchQueryRes {
  search: [
    {
      name: string;
      link: string;
      type: SearchResultType;
      score: number;
    }
  ];
}

const searchQuery = gql`
  query search($text: String) {
    search(text: $text) {
      name
      link
      type
      score
    }
  }
`;

interface SearchResult {
  id: string;
  title: string;
  link: string;
  type: SearchResultType;
}

interface State {
  searchError: boolean;
  searchText: string;
  searchLoading: boolean;
  searchResults: SearchResult[];
}

class SearchBar extends PureComponent<RouteComponentProps, State> {
  public state = {
    searchError: false,
    searchLoading: false,
    searchResults: [],
    searchText: '',
  };

  private doSearch = _.debounce((client: ApolloClient<any>, value: string) => {
    client
      .query<SearchQueryRes>({
        query: searchQuery,
        variables: { text: value },
      })
      .then((results: ApolloQueryResult<SearchQueryRes>) => {
        this.setState({
          searchError: false,
          searchLoading: false,
          searchResults: this.queryDataToSearchResults(results.data),
        });
      })
      .catch(() => {
        this.setState({
          searchError: true,
          searchLoading: false,
          searchResults: [],
        });
      });
  }, 300);

  public render() {
    const { searchError, searchText, searchLoading, searchResults } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <Search
            aria-label="Search games, runners, platforms, and genres"
            placeholder="Search games, runners&hellip;"
            className="menusearch"
            loading={searchLoading}
            showNoResults={!searchLoading || searchError}
            onResultSelect={this.handleResultSelect}
            onSearchChange={this.handleSearchChange(client)}
            results={searchResults}
            resultRenderer={this.resultRenderer}
            value={searchText}
            noResultsMessage={searchError ? 'Error running search.' : undefined}
          />
        )}
      </ApolloConsumer>
    );
  }

  private resultRenderer = (props: SearchResultProps) => <SearchResultComponent {...props} />;

  private handleResultSelect = (e: React.MouseEvent<HTMLDivElement>, { result }: SearchResultData) => {
    this.props.history.push(result.link);
  };

  private handleSearchChange = (client: ApolloClient<any>) => (
    e: React.MouseEvent<HTMLElement>,
    searchProps: SearchProps
  ) => {
    if (!searchProps.value) {
      this.setState({
        searchLoading: false,
        searchResults: [],
        searchText: '',
      });
    } else {
      this.setState({
        searchError: false,
        searchLoading: true,
        searchText: searchProps.value,
      });
      this.doSearch(client, searchProps.value);
    }
  };

  private queryDataToSearchResults = (queryData: SearchQueryRes): SearchResult[] => {
    if (!queryData || !queryData.search) {
      return [];
    }

    return queryData.search.slice(0, 10).map(res => ({
      id: res.link.replace('/', '_'),
      link: res.link,
      title: res.name,
      type: res.type,
    }));
  };
}

const SearchBarWithRouter = withRouter(SearchBar);

export default SearchBarWithRouter;
export { SearchBarWithRouter as SearchBar };
