/* tslint:disable:object-literal-sort-keys */

import {
  Event,
  Game,
  GameTableCol,
  Genre,
  Platform,
  PlatformTableCol,
  Run,
  Runner,
  RunnerTableCol,
  RunTableCol,
  SiteCategory,
  SortDir,
} from '../types';

// All sort functions assume that the property being sorted by is available (unless it's nullable)
const sortByStartTime = (a: Run, b: Run): number => a.startTime - b.startTime;
const sortByEventStartDate = (a: Run, b: Run): number => a.event.startDate.localeCompare(b.event.startDate);
const sortByGameName = (a: Run, b: Run): number => a.game.name.localeCompare(b.game.name);
const sortByGameYear = (a: Run, b: Run): number => a.game.year - b.game.year;
const sortByPlatform = (a: Run, b: Run): number => a.game.platform.name.localeCompare(b.game.platform.name);
const sortByCategory = (a: Run, b: Run): number => (a.runCategory || '\uFFFF').localeCompare(b.runCategory || '\uFFFF');
const sortByGenre = (a: Run, b: Run): number => a.game.genre.name.localeCompare(b.game.genre.name);
const sortByFirstRunnerName = (a: Run, b: Run): number => a.runners[0].name.localeCompare(b.runners[0].name);
const sortByDuration = (a: Run, b: Run): number => {
  // Duration is assumed to be HH:MM:SS
  const getDuration = (str: string): number => {
    const [hours, mins, secs] = str.split(':');
    return parseInt(hours, 10) * 60 * 60 + parseInt(mins, 10) * 60 + parseInt(secs, 10);
  };

  return getDuration(a.duration) - getDuration(b.duration);
};
const sortBySiteCategory = (catId: string, a: Run, b: Run): number => {
  const aVal = a.siteCategories.find(c => c.id === catId) ? 1 : 0;
  const bVal = b.siteCategories.find(c => c.id === catId) ? 1 : 0;
  return aVal - bVal;
};

const defaultSort = sortByStartTime;
const gameColSort = (a: Run, b: Run) => sortByGameName(a, b) || sortByGameYear(a, b) || defaultSort(a, b);
const yearColSort = (a: Run, b: Run) => sortByGameYear(a, b) || sortByGameName(a, b) || defaultSort(a, b);
const platformColSort = (a: Run, b: Run) => sortByPlatform(a, b) || gameColSort(a, b);
const categoryColSort = (a: Run, b: Run) => sortByCategory(a, b) || defaultSort(a, b);
const genreColSort = (a: Run, b: Run) =>
  sortByGenre(a, b) || sortByGameName(a, b) || sortByGameYear(a, b) || defaultSort(a, b);
const eventColSort = (a: Run, b: Run) => sortByEventStartDate(a, b) || defaultSort(a, b);
const runnersColSort = (a: Run, b: Run) => sortByFirstRunnerName(a, b) || defaultSort(a, b);
const timeColSort = (a: Run, b: Run) => sortByDuration(a, b) || defaultSort(a, b);
const siteCategoryColSort = (catId: string, a: Run, b: Run) =>
  sortBySiteCategory(catId, a, b) || sortByCategory(a, b) || defaultSort(a, b);

const RunSortFuncs = {
  [RunTableCol.Vods]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => defaultSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * defaultSort(a, b),
  },
  [RunTableCol.StartTime]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => sortByStartTime(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * sortByStartTime(a, b),
  },
  [RunTableCol.Game]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => gameColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * gameColSort(a, b),
  },
  [RunTableCol.GameNoYear]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => gameColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * gameColSort(a, b),
  },
  [RunTableCol.Year]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => yearColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * yearColSort(a, b),
  },
  [RunTableCol.Platform]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => platformColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * platformColSort(a, b),
  },
  [RunTableCol.Category]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => categoryColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * categoryColSort(a, b),
  },
  [RunTableCol.Genre]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => genreColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * genreColSort(a, b),
  },
  [RunTableCol.Event]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => eventColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * eventColSort(a, b),
  },
  [RunTableCol.Runners]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => runnersColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * runnersColSort(a, b),
  },
  [RunTableCol.Time]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => timeColSort(a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * timeColSort(a, b),
  },
  [RunTableCol.WorldRecord]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => siteCategoryColSort('world-record-runs', a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * siteCategoryColSort('world-record-runs', a, b),
  },
  [RunTableCol.Handicapped]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => siteCategoryColSort('handicapped-runs', a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * siteCategoryColSort('handicapped-runs', a, b),
  },
  [RunTableCol.DevCommentary]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => siteCategoryColSort('developer-commentaries', a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * siteCategoryColSort('developer-commentaries', a, b),
  },
  [RunTableCol.Race]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => siteCategoryColSort('races', a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * siteCategoryColSort('races', a, b),
  },
  [RunTableCol.Tas]: {
    [SortDir.Ascending]: (a: Run, b: Run): number => siteCategoryColSort('tool-assisted-speedruns', a, b),
    [SortDir.Descending]: (a: Run, b: Run): number => -1 * siteCategoryColSort('tool-assisted-speedruns', a, b),
  },
};

export const RunSortDefaults = {
  [RunTableCol.Vods]: SortDir.Descending,
  [RunTableCol.StartTime]: SortDir.Descending,
  [RunTableCol.Game]: SortDir.Ascending,
  [RunTableCol.GameNoYear]: SortDir.Ascending,
  [RunTableCol.Year]: SortDir.Descending,
  [RunTableCol.Platform]: SortDir.Ascending,
  [RunTableCol.Category]: SortDir.Ascending,
  [RunTableCol.Genre]: SortDir.Ascending,
  [RunTableCol.Event]: SortDir.Descending,
  [RunTableCol.Runners]: SortDir.Ascending,
  [RunTableCol.Time]: SortDir.Ascending,
  [RunTableCol.WorldRecord]: SortDir.Descending,
  [RunTableCol.Handicapped]: SortDir.Descending,
  [RunTableCol.DevCommentary]: SortDir.Descending,
  [RunTableCol.Race]: SortDir.Descending,
  [RunTableCol.Tas]: SortDir.Descending,
};

export const getRunSortFunc = (col: RunTableCol, dir: SortDir) =>
  RunSortFuncs[col] && RunSortFuncs[col][dir] ? RunSortFuncs[col][dir] : defaultSort;

export const sortEvents = (a: Event, b: Event) => -1 * a.startDate.localeCompare(b.startDate);

export const sortGenres = (a: Genre, b: Genre) => a.name.localeCompare(b.name);

export const sortSiteCategories = (a: SiteCategory, b: SiteCategory) => a.name.localeCompare(b.name);

const PlatformSortFuncs = {
  [PlatformTableCol.Name]: {
    [SortDir.Ascending]: (a: Platform, b: Platform): number => a.name.localeCompare(b.name),
    [SortDir.Descending]: (a: Platform, b: Platform): number => b.name.localeCompare(a.name),
  },
  [PlatformTableCol.Company]: {
    [SortDir.Ascending]: (a: Platform, b: Platform): number =>
      (a.company || '\uFFFF').localeCompare(b.company || '\uFFFF'),
    [SortDir.Descending]: (a: Platform, b: Platform): number =>
      (b.company || '\uFFFF').localeCompare(a.company || '\uFFFF'),
  },
  [PlatformTableCol.LaunchYear]: {
    [SortDir.Ascending]: (a: Platform, b: Platform): number => (a.launchYear || 0) - (b.launchYear || 0),
    [SortDir.Descending]: (a: Platform, b: Platform): number => (b.launchYear || 0) - (a.launchYear || 0),
  },
  [PlatformTableCol.RunCount]: {
    [SortDir.Ascending]: (a: Platform, b: Platform): number => (a.runCount || 0) - (b.runCount || 0),
    [SortDir.Descending]: (a: Platform, b: Platform): number => (b.runCount || 0) - (a.runCount || 0),
  },
};

export const PlatformSortDefaults = {
  [PlatformTableCol.Name]: SortDir.Ascending,
  [PlatformTableCol.Company]: SortDir.Ascending,
  [PlatformTableCol.LaunchYear]: SortDir.Descending,
  [PlatformTableCol.RunCount]: SortDir.Descending,
};

export const getPlatformSortFunc = (col: PlatformTableCol, dir: SortDir) =>
  PlatformSortFuncs[col] && PlatformSortFuncs[col][dir]
    ? PlatformSortFuncs[col][dir]
    : PlatformSortFuncs[PlatformTableCol.Name][SortDir.Ascending];

const GameSortFuncs = {
  [GameTableCol.Name]: {
    [SortDir.Ascending]: (a: Game, b: Game): number => a.name.localeCompare(b.name),
    [SortDir.Descending]: (a: Game, b: Game): number => b.name.localeCompare(a.name),
  },
  [GameTableCol.Year]: {
    [SortDir.Ascending]: (a: Game, b: Game): number => (a.year || 0) - (b.year || 0),
    [SortDir.Descending]: (a: Game, b: Game): number => (b.year || 0) - (a.year || 0),
  },
  [GameTableCol.Platform]: {
    [SortDir.Ascending]: (a: Game, b: Game): number =>
      (a.platform.name || '\uFFFF').localeCompare(b.platform.name || '\uFFFF'),
    [SortDir.Descending]: (a: Game, b: Game): number =>
      (b.platform.name || '\uFFFF').localeCompare(a.platform.name || '\uFFFF'),
  },
  [GameTableCol.Genre]: {
    [SortDir.Ascending]: (a: Game, b: Game): number => (a.genre.name || '').localeCompare(b.genre.name || ''),
    [SortDir.Descending]: (a: Game, b: Game): number => (b.genre.name || '').localeCompare(a.genre.name || ''),
  },
  [GameTableCol.RunCount]: {
    [SortDir.Ascending]: (a: Game, b: Game): number => (a.runCount || 0) - (b.runCount || 0),
    [SortDir.Descending]: (a: Game, b: Game): number => (b.runCount || 0) - (a.runCount || 0),
  },
};

export const GameSortDefaults = {
  [GameTableCol.Name]: SortDir.Ascending,
  [GameTableCol.Year]: SortDir.Descending,
  [GameTableCol.Platform]: SortDir.Ascending,
  [GameTableCol.Genre]: SortDir.Ascending,
  [GameTableCol.RunCount]: SortDir.Descending,
};

export const getGameSortFunc = (col: GameTableCol, dir: SortDir) =>
  GameSortFuncs[col] && GameSortFuncs[col][dir]
    ? GameSortFuncs[col][dir]
    : GameSortFuncs[GameTableCol.Name][SortDir.Ascending];

const RunnerSortFuncs = {
  [RunnerTableCol.Name]: {
    [SortDir.Ascending]: (a: Runner, b: Runner): number => a.name.localeCompare(b.name),
    [SortDir.Descending]: (a: Runner, b: Runner): number => b.name.localeCompare(a.name),
  },
  [RunnerTableCol.Links]: {
    [SortDir.Ascending]: (a: Runner, b: Runner): number => a.name.localeCompare(b.name),
    [SortDir.Descending]: (a: Runner, b: Runner): number => b.name.localeCompare(a.name),
  },
  [RunnerTableCol.RunCount]: {
    [SortDir.Ascending]: (a: Runner, b: Runner): number => (a.runCount || 0) - (b.runCount || 0),
    [SortDir.Descending]: (a: Runner, b: Runner): number => (b.runCount || 0) - (a.runCount || 0),
  },
};

export const RunnerSortDefaults = {
  [RunnerTableCol.Name]: SortDir.Ascending,
  [RunnerTableCol.Links]: SortDir.Ascending,
  [RunnerTableCol.RunCount]: SortDir.Descending,
};

export const getRunnerSortFunc = (col: RunnerTableCol, dir: SortDir) =>
  RunnerSortFuncs[col] && RunnerSortFuncs[col][dir]
    ? RunnerSortFuncs[col][dir]
    : RunnerSortFuncs[RunnerTableCol.Name][SortDir.Ascending];
