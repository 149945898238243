import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Image, Menu as SemanticMenu, Responsive } from 'semantic-ui-react';
import { dropdownMenuBreakpoint, smallSearchBreakpoint } from '../../utils';

import menuItems from './menuItems';
import MenuSection from './MenuSection';
import SearchBar from './SearchBar';

import './Menu.css';

class Menu extends PureComponent {
  public render() {
    return (
      <nav>
        <SemanticMenu color="blue" inverted={true} className="squared">
          <SemanticMenu.Item name="home" header={true} fitted="vertically" link={true} as={Link} to="/">
            <Image src="/img/logo.png" className="logo" size="mini" alt="GDQ VODs logo" />
            <Responsive as="span" minWidth={smallSearchBreakpoint}>
              GDQ VODs
            </Responsive>
          </SemanticMenu.Item>

          {menuItems.desktop.map((section, index) => (
            <MenuSection section={section} key={index} />
          ))}

          <Responsive as={Dropdown} item={true} text="Menu" maxWidth={dropdownMenuBreakpoint - 1}>
            <Dropdown.Menu>
              {menuItems.mobile.map((item, index) => (
                <Dropdown.Item key={index} as={Link} to={item.to} text={item.name} />
              ))}
            </Dropdown.Menu>
          </Responsive>

          <SemanticMenu.Item
            position="right"
            className="borderless"
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            <SearchBar />
          </SemanticMenu.Item>
        </SemanticMenu>
      </nav>
    );
  }
}

export default Menu;
export { Menu };
