import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import { Runner } from '../../types';
import { facebookUserHref, twitchUserHref, twitterUserHref, youtubeUserHref, youtubeUserName } from '../../utils';
import LoadingTableCell from '../Shared/LoadingTableCell';

interface Props {
  runner?: Runner;
}

class RunnersTableRow extends PureComponent<Props> {
  public render() {
    const { runner } = this.props;
    if (!runner) {
      return (
        <Table.Row>
          <LoadingTableCell loaderWidth={40} randomRange={40} />
          <LoadingTableCell loaderWidth={40} randomRange={40} />
          <LoadingTableCell loaderWidth={20} randomRange={20} />
        </Table.Row>
      );
    }

    return (
      <Table.Row key={runner.id}>
        <Table.Cell>
          <Link to={`/runner/${runner.id}`}>{runner.name}</Link>
        </Table.Cell>
        <Table.Cell>
          {this.renderLinks().map((link, i) => (
            <Fragment key={`links-${i}`}>
              {!!i && ' ·  '}
              {link}
            </Fragment>
          ))}
        </Table.Cell>
        <Table.Cell>{runner.runCount}</Table.Cell>
      </Table.Row>
    );
  }

  private renderLinks() {
    const { runner } = this.props;
    const links = [];
    if (runner && runner.website) {
      links.push(
        <a href={runner.website} rel="noopener noreferrer" target="_blank">
          <Icon name="home" /> {runner.websiteName}
        </a>
      );
    }
    if (runner && runner.twitch) {
      links.push(
        <a href={twitchUserHref(runner.twitch)} rel="noopener noreferrer" target="_blank">
          <Icon name="twitch" /> {runner.twitch}
        </a>
      );
    }
    if (runner && runner.twitter) {
      links.push(
        <a href={twitterUserHref(runner.twitter)} rel="noopener noreferrer" target="_blank">
          <Icon name="twitter" /> {runner.twitter}
        </a>
      );
    }
    if (runner && runner.youtube) {
      links.push(
        <a href={youtubeUserHref(runner.youtube)} rel="noopener noreferrer" target="_blank">
          <Icon name="youtube" />
          {youtubeUserName(runner.youtube)}
        </a>
      );
    }
    if (runner && runner.facebook) {
      links.push(
        <a href={facebookUserHref(runner.facebook)} rel="noopener noreferrer" target="_blank">
          <Icon name="facebook" /> {runner.facebook}
        </a>
      );
    }

    return links;
  }
}

export default RunnersTableRow;
export { RunnersTableRow };
