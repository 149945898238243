import React from 'react';
import { getWithConsent, setWithConsent } from '../../utils';

export function getInitialDarkMode() {
  const local = getWithConsent('darkMode');
  return local !== null ? local === 'y' : matchMedia('(prefers-color-scheme: dark)').matches;
}

export function getInitialAutoplay() {
  return getWithConsent('autoplay') === 'y';
}

export function getInitialRandom() {
  return getWithConsent('random') === 'y';
}

export function getInitialWatched() {
  return new Set((getWithConsent('watched') || '').split(','));
}

export function getInitialHideRunTimes() {
  return getWithConsent('hideRunTimes') === 'y';
}

export function persistWatched(watched: Set<string>) {
  const watchedString = Array.from(watched.keys()).join(',');
  setWithConsent('watched', watchedString);
}

export const GlobalContext = React.createContext({
  addWatchedRun: (runId: string) => {}, // tslint:disable-line:no-empty
  clearWatchedRun: (runId: string) => {}, // tslint:disable-line:no-empty
  clearWatchedRuns: () => {}, // tslint:disable-line:no-empty
  darkMode: getInitialDarkMode(),
  hideRunTimes: getInitialHideRunTimes(),
  isAutoplayOn: getInitialAutoplay(),
  isRandomOn: getInitialRandom(),
  toggleAutoplay: () => {}, // tslint:disable-line:no-empty
  toggleDarkMode: () => {}, // tslint:disable-line:no-empty
  toggleHideRunTimes: () => {}, // tslint:disable-line:no-empty
  toggleRandom: () => {}, // tslint:disable-line:no-empty
  watchedRunIds: getInitialWatched(),
});

export default GlobalContext;
