import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import { RUNNERS_QUERY } from '../../apollo';
import { RunnerQueryData } from '../../types';
import { RunnersTable } from '../IndexTables';
import Ad from '../Shared/Ad';
import StandardHelmet from '../Shared/StandardHelmet';

const title = 'GDQ VODs · Runners';
const description =
  'All the runners that have run a game at a Games Done Quick event, sortable by name, number of runs, etc.';

class RunnersPage extends PureComponent {
  public render() {
    return (
      <Query<RunnerQueryData> query={RUNNERS_QUERY}>
        {({ data, error, loading }: QueryResult<RunnerQueryData>) => {
          const runners = this.getRunners(data);
          const loadingFirstTime = loading && runners.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <Container>
                <Ad key="RunnersPage" />
                <RunnersTable loading={loadingFirstTime} error={!!error} runners={runners} />
              </Container>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getRunners(data?: RunnerQueryData) {
    return (data && data.runners) || [];
  }
}

export default RunnersPage;
export { RunnersPage };
