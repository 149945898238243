import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { Platform, PlatformTableCol, SortDir } from '../../types';
import { getPlatformSortFunc, PlatformSortDefaults, sortDirToString } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { ErrorContainer } from '../Shared';
import PlatformsTableRow from './PlatformsTableRow';

interface Props {
  loading: boolean;
  error: boolean;
  platforms: Platform[];
}

interface State {
  sortCol: PlatformTableCol;
  sortDir: SortDir;
}

class PlatformsTable extends PureComponent<Props, State> {
  public state = {
    sortCol: PlatformTableCol.Name,
    sortDir: SortDir.Ascending,
  };

  public getSorted = (col: PlatformTableCol) =>
    sortDirToString(this.state.sortCol === col ? this.state.sortDir : undefined);

  public handleSort = (col: PlatformTableCol) => () => {
    const { sortCol, sortDir } = this.state;

    if (sortCol === col) {
      this.setState({
        sortDir: sortDir === SortDir.Ascending ? SortDir.Descending : SortDir.Ascending,
      });
    } else {
      this.setState({ sortCol: col, sortDir: PlatformSortDefaults[col] });
    }
  };

  public render() {
    const { loading, error, platforms } = this.props;
    const { sortCol, sortDir } = this.state;

    if (error) {
      return <ErrorContainer />;
    }

    const sortedPlatforms = platforms.sort(getPlatformSortFunc(sortCol, sortDir));

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Table compact={true} sortable={true} inverted={darkMode}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={this.getSorted(PlatformTableCol.Name)}
                  onClick={this.handleSort(PlatformTableCol.Name)}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(PlatformTableCol.Company)}
                  onClick={this.handleSort(PlatformTableCol.Company)}
                >
                  Company
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(PlatformTableCol.LaunchYear)}
                  onClick={this.handleSort(PlatformTableCol.LaunchYear)}
                >
                  Launch Year
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(PlatformTableCol.RunCount)}
                  onClick={this.handleSort(PlatformTableCol.RunCount)}
                >
                  Runs
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading && [...Array(30)].map((_, i) => <PlatformsTableRow key={`loading-row-${i}`} />)}
              {!loading && sortedPlatforms.map(platform => <PlatformsTableRow key={platform.id} platform={platform} />)}
            </Table.Body>
          </Table>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default PlatformsTable;
export { PlatformsTable };
