import React, { PureComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { Card } from 'semantic-ui-react';
import { backgroundColour, cardStyle, loaderPrimaryColour, loaderSecondaryColour } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';

class LoadingCard extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Card style={cardStyle(darkMode)}>
            <ContentLoader
              height={290}
              width={290}
              speed={5}
              primaryColor={loaderPrimaryColour(darkMode)}
              secondaryColor={loaderSecondaryColour(darkMode)}
            >
              <rect x="0" y="0" rx="0" ry="0" width="290" height="290" />
            </ContentLoader>
            <Card.Content style={backgroundColour(darkMode)}>
              <ContentLoader
                height={100}
                width={290}
                primaryColor={loaderPrimaryColour(darkMode)}
                secondaryColor={loaderSecondaryColour(darkMode)}
              >
                <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
                <rect x="0" y="30" rx="3" ry="3" width="260" height="15" />
                <rect x="0" y="50" rx="3" ry="3" width="270" height="15" />
                <rect x="0" y="70" rx="3" ry="3" width="160" height="15" />
              </ContentLoader>
            </Card.Content>
          </Card>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default LoadingCard;
export { LoadingCard };
