import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import { PLATFORMS_QUERY } from '../../apollo';
import { PlatformQueryData } from '../../types';
import { PlatformsTable } from '../IndexTables';
import Ad from '../Shared/Ad';
import StandardHelmet from '../Shared/StandardHelmet';

const title = 'GDQ VODs · Platforms';
const description =
  'All the platforms used at Games Done Quick events, sortable by name, launch year, number of runs, etc.';

class PlatformsPage extends PureComponent {
  public render() {
    return (
      <Query<PlatformQueryData> query={PLATFORMS_QUERY}>
        {({ data, error, loading }: QueryResult<PlatformQueryData>) => {
          const platforms = this.getPlatforms(data);
          const loadingFirstTime = loading && platforms.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <Container>
                <Ad key="PlatformsPage" />
                <PlatformsTable loading={loadingFirstTime} error={!!error} platforms={platforms} />
              </Container>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getPlatforms(data?: PlatformQueryData) {
    return (data && data.platforms) || [];
  }
}

export default PlatformsPage;
export { PlatformsPage };
