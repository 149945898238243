import React, { PureComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { RunTableCol } from '../../types';
import { LoadingCell } from './LoadingCells';

interface Props {
  cols: RunTableCol[];
}

class RunTableLoadingRow extends PureComponent<Props> {
  public render() {
    const { cols } = this.props;
    return (
      <Table.Row>
        {cols.map(c => (
          <LoadingCell key={c.toString()} col={c} />
        ))}
      </Table.Row>
    );
  }
}

export { RunTableLoadingRow };
