import React, { Fragment, PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { Query, QueryResult } from 'react-apollo';
import { GENRE_QUERY } from '../../apollo';
import { Genre, GenreQueryData, GenreQueryVars } from '../../types';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  genreId: string;
}

class GenreSegment extends PureComponent<Props> {
  public render() {
    const { genreId } = this.props;
    return (
      <Query<GenreQueryData, GenreQueryVars> query={GENRE_QUERY} variables={{ genre: genreId }}>
        {({ data, error, loading }: QueryResult<GenreQueryData, GenreQueryVars>) => {
          const genre = this.getGenre(data);
          const loadingFirstTime = loading && !genre;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <StandardHelmet title={this.getTitle(genre)} description={this.getDescription(genreId, genre)} />
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && genre && (
                  <Fragment>
                    <Header>{genre.name}</Header>
                    <Markdown markdown={`${genre.description}\n\n${this.getDescription(genreId, genre)}`} />
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(genre?: Genre) {
    const title = 'GDQ VODs';
    return genre ? `${title} · ${genre.name}` : title;
  }

  private getDescription(genreId: string, genre?: Genre) {
    const nameToUse = genre ? genre.name : genreId;
    return `Watch the VODs of all ${nameToUse} game runs here and browse through related games, runners, and special categories.`;
  }

  private getGenre(genreData?: GenreQueryData) {
    return genreData && genreData.genres ? genreData.genres[0] : undefined;
  }
}

export default GenreSegment;
export { GenreSegment };
