/* tslint:disable:max-classes-per-file */

import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import { Button, Grid, Header, Icon, List, Popup } from 'semantic-ui-react';
import { Run, VodSource } from '../../types';
import { humanDateTime } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { SiteCategoryLabel } from '../Shared';
import { EventLink, GameWithYearLink, GenreLink, PlatformLink, RunnerLinks } from '../Shared/MiscLinks';
import { VodLinks } from '../Shared/VodLinks';

interface Props {
  colSpan: number;
  run: Run;
  vodIndex: number;
  videoIndex: number;
  isWatched: boolean;
  setPlayingVod: (runId: string, vodIndex?: number, videoIndex?: number) => void;
  closePlayingVod: () => void;
  playPrevRun?: () => void;
  playNextRun?: () => void;
  onVideoEnded?: () => void;
}

const closeIconStyles = {
  cursor: 'pointer',
  marginRight: 0,
  opacity: '.8',
  paddingTop: 0,
  width: '1.5rem',
};

class ExpandedRowHeader extends PureComponent<Props> {
  public render() {
    const { run, closePlayingVod } = this.props;

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Grid.Row>
            <Grid.Column width={16}>
              <Header
                as="h3"
                textAlign="center"
                block={true}
                inverted={darkMode}
                style={{
                  alignItems: 'center',
                  border: darkMode ? 'none' : undefined,
                  display: 'flex',
                }}
              >
                <span style={{ flex: '1' }}>
                  <EventLink event={run.event} />
                  {' - '}
                  <GameWithYearLink game={run.game} />
                  {' - '}
                  <PlatformLink platform={run.game.platform} />
                  {run.runCategory && ` - ${run.runCategory}`}
                </span>
                <Icon name="close" size="big" style={closeIconStyles} onClick={closePlayingVod} />
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
      </GlobalContext.Consumer>
    );
  }
}

class RandomButton extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ isRandomOn, toggleRandom, darkMode }) => (
          <Popup
            trigger={<Button icon="random" inverted={darkMode} positive={isRandomOn} onClick={toggleRandom} />}
            content="Randomize next video"
          />
        )}
      </GlobalContext.Consumer>
    );
  }
}

class AutoplayButton extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ isAutoplayOn, toggleAutoplay, darkMode }) => (
          <Popup
            trigger={
              <Button icon="redo alternate" inverted={darkMode} positive={isAutoplayOn} onClick={toggleAutoplay} />
            }
            content="Enable autoplay of next video"
          />
        )}
      </GlobalContext.Consumer>
    );
  }
}

class SetUnwatchedButton extends PureComponent<{ runId: string; isWatched: boolean; darkMode?: boolean }> {
  public render() {
    const { runId, isWatched, darkMode } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ clearWatchedRun }) => {
          const onClick = () => clearWatchedRun(runId);
          return (
            <Popup
              trigger={<Button icon="eye slash" inverted={darkMode} positive={!isWatched} onClick={onClick} />}
              content="Set Unwatched"
            />
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

class ExpandedRowSideInfo extends PureComponent<Props> {
  public render() {
    const { run, isWatched, setPlayingVod, playPrevRun, playNextRun } = this.props;

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Grid.Column width={4} style={{ display: 'inline-flex', flexDirection: 'column' }}>
            <List inverted={darkMode} style={{ flex: '1' }}>
              <List.Item>
                <div className="header">Genre</div>
                <div className="description">
                  <GenreLink genre={run.game.genre} />
                </div>
              </List.Item>
              <List.Item>
                <div className="header">Runners</div>
                <div className="description">
                  <RunnerLinks runners={run.runners} showPopup={true} />
                </div>
              </List.Item>
              <List.Item>
                <div className="header">Start Time</div>
                <div className="description">{humanDateTime(run.startTime, run.event.timezone)}</div>
              </List.Item>
              <List.Item>
                <div className="header">Length</div>
                <div className="description">
                  <span className="runTime">{run.duration}</span>
                </div>
              </List.Item>
              {run.siteCategories.length > 0 && (
                <List.Item>
                  <div className="header">Tags</div>
                  <div className="description">
                    {run.siteCategories.map(c => (
                      <SiteCategoryLabel key={`category-label-${c.id}`} category={c} />
                    ))}
                  </div>
                </List.Item>
              )}
              <List.Item>
                <div className="header">VODs</div>
                <div className="description">
                  <VodLinks run={run} setPlayingVod={setPlayingVod} showPopup={true} />
                </div>
              </List.Item>
            </List>
            <div style={{ textAlign: 'center', marginRight: '1em' }}>
              <Button.Group compact={true} icon={true} inverted={darkMode}>
                <Button icon="fast backward" inverted={darkMode} disabled={!playPrevRun} onClick={playPrevRun} />
                <AutoplayButton />
                <RandomButton />
                <Button icon="fast forward" inverted={darkMode} disabled={!playNextRun} onClick={playNextRun} />
              </Button.Group>
              <Button.Group compact={true} icon={true} inverted={darkMode} style={{ marginLeft: '0.5rem' }}>
                <SetUnwatchedButton runId={run.id} isWatched={isWatched} darkMode={darkMode} />
              </Button.Group>
            </div>
          </Grid.Column>
        )}
      </GlobalContext.Consumer>
    );
  }
}

class ExpandedRowVideoEmbed extends PureComponent<Props> {
  public render() {
    const { run, vodIndex, videoIndex, onVideoEnded } = this.props;

    const vod = run.vods[vodIndex];
    const { source, start, end } = vod;

    const videoId = vod.videoIds[videoIndex];

    let url: string;
    if (source === VodSource.YouTube) {
      url = `https://www.youtube.com/watch?v=${videoId}`;
    } else if (source === VodSource.Twitch) {
      url = `https://www.twitch.tv/videos/${videoId.substr(1)}`;
    }

    const config = {
      twitch: {
        options: {
          time: start,
        },
      },
      youtube: {
        playerVars: {
          autohide: 1,
          end,
          origin: 'http://gdqvods.com',
          start,
        },
      },
    };

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Grid.Column width={12}>
            <div className="ui embed active" style={{ background: darkMode ? '#1b1c1d' : undefined }}>
              <div className="embed">
                <ReactPlayer
                  url={url}
                  playing={true}
                  controls={true}
                  width="100%"
                  height="100%"
                  config={config}
                  onEnded={onVideoEnded}
                />
              </div>
            </div>
          </Grid.Column>
        )}
      </GlobalContext.Consumer>
    );
  }
}

class PlayingVodCell extends PureComponent<Props> {
  private el: Element | null = null;

  public componentDidMount() {
    this.scrollIntoView();
  }

  public componentDidUpdate() {
    this.scrollIntoView();
  }

  public render() {
    const { colSpan } = this.props;

    return (
      <td
        colSpan={colSpan}
        ref={el => {
          this.el = el;
        }}
      >
        <Grid stackable={true}>
          <ExpandedRowHeader {...this.props} />
          <Grid.Row style={{ paddingTop: 0 }}>
            <ExpandedRowVideoEmbed {...this.props} />
            <ExpandedRowSideInfo {...this.props} />
          </Grid.Row>
        </Grid>
      </td>
    );
  }

  private scrollIntoView() {
    if (this.el) {
      this.el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

export default PlayingVodCell;
export { PlayingVodCell };
