import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link, Route } from 'react-router-dom';
import { GlobalContextType } from '../../types';
import { setWithConsent } from '../../utils';
import Footer from '../Footer';
import Menu from '../Menu';
import {
  EventPage,
  EventsPage,
  GamePage,
  GamesPage,
  GenrePage,
  GenresPage,
  HomePage,
  PlatformPage,
  PlatformsPage,
  PrivacyPolicyPage,
  RunnerPage,
  RunnersPage,
  SiteCategoriesPage,
  SiteCategoryPage,
  TosPage,
} from '../Pages';
import {
  getInitialAutoplay,
  getInitialDarkMode,
  getInitialHideRunTimes,
  getInitialRandom,
  getInitialWatched,
  GlobalContext,
  persistWatched,
} from './GlobalContext';

function updateDarkModeCss(darkMode: boolean) {
  if (darkMode) {
    document.body.classList.add('darkMode');
  } else {
    document.body.classList.remove('darkMode');
  }
}

function updateHideRunTimesCss(darkMode: boolean) {
  if (darkMode) {
    document.body.classList.add('hideRunTimes');
  } else {
    document.body.classList.remove('hideRunTimes');
  }
}

class App extends Component<{}, GlobalContextType> {
  constructor(props: any) {
    super(props);

    const toggleDarkMode = () => {
      const newDarkMode = !this.state.darkMode;

      this.setState({ darkMode: newDarkMode });
      setWithConsent('darkMode', newDarkMode ? 'y' : 'n');
      updateDarkModeCss(newDarkMode);
    };

    const toggleAutoplay = () => {
      const newAutoplay = !this.state.isAutoplayOn;

      this.setState({ isAutoplayOn: newAutoplay });
      setWithConsent('autoplay', newAutoplay ? 'y' : 'n');
    };

    const toggleRandom = () => {
      const newRandom = !this.state.isRandomOn;

      this.setState({ isRandomOn: newRandom });
      setWithConsent('random', newRandom ? 'y' : 'n');
    };

    const addWatchedRun = (runId: string) => {
      const newWatched = new Set(this.state.watchedRunIds);
      newWatched.add(runId);

      this.setState({ watchedRunIds: newWatched });
      persistWatched(newWatched);
    };

    const clearWatchedRun = (runId: string) => {
      const newWatched = new Set(this.state.watchedRunIds);
      newWatched.delete(runId);

      this.setState({ watchedRunIds: newWatched });
      persistWatched(newWatched);
    };

    const clearWatchedRuns = () => {
      const newWatched = new Set();

      this.setState({ watchedRunIds: newWatched });
      persistWatched(newWatched);
    };

    const toggleHideRunTimes = () => {
      const newHide = !this.state.hideRunTimes;

      this.setState({ hideRunTimes: newHide });
      setWithConsent('hideRunTimes', newHide ? 'y' : 'n');
      updateHideRunTimesCss(newHide);
    };

    this.state = {
      addWatchedRun,
      clearWatchedRun,
      clearWatchedRuns,
      darkMode: getInitialDarkMode(),
      hideRunTimes: getInitialHideRunTimes(),
      isAutoplayOn: getInitialAutoplay(),
      isRandomOn: getInitialRandom(),
      toggleAutoplay,
      toggleDarkMode,
      toggleHideRunTimes,
      toggleRandom,
      watchedRunIds: getInitialWatched(),
    };
  }

  public componentDidMount() {
    updateDarkModeCss(this.state.darkMode);
    updateHideRunTimesCss(this.state.hideRunTimes);
  }

  public render() {
    return (
      <GlobalContext.Provider value={this.state}>
        <Menu />
        <main>
          <Route exact={true} path="/" component={HomePage} />
          <Route exact={true} path="/events" component={EventsPage} />
          <Route exact={true} path="/platforms" component={PlatformsPage} />
          <Route exact={true} path="/genres" component={GenresPage} />
          <Route exact={true} path="/categories" component={SiteCategoriesPage} />
          <Route exact={true} path="/games" component={GamesPage} />
          <Route exact={true} path="/runners" component={RunnersPage} />
          <Route exact={true} path="/privacy" component={PrivacyPolicyPage} />
          <Route exact={true} path="/terms" component={TosPage} />
          <Route path="/event/:id" component={EventPage} />
          <Route path="/platform/:id" component={PlatformPage} />
          <Route path="/genre/:id" component={GenrePage} />
          <Route path="/category/:id" component={SiteCategoryPage} />
          <Route path="/game/:id" component={GamePage} />
          <Route path="/runner/:id" component={RunnerPage} />
        </main>
        <Footer />
        <CookieConsent>
          This website uses cookies to enhance the user experience. <Link to="privacy">Learn more.</Link>
        </CookieConsent>
      </GlobalContext.Provider>
    );
  }
}

export default App;
export { App };
