import React, { PureComponent } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { Game, GameTableCol, SortDir } from '../../types';
import { GameSortDefaults, getGameSortFunc, sortDirToString } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { ErrorContainer } from '../Shared';
import GamesTableRow from './GamesTableRow';

interface Props {
  loading: boolean;
  error: boolean;
  games: Game[];
}

interface State {
  sortCol: GameTableCol;
  sortDir: SortDir;
  page: number;
}

const PAGE_SIZE = 100;

class GamesTable extends PureComponent<Props, State> {
  public state = {
    page: 0,
    sortCol: GameTableCol.Name,
    sortDir: SortDir.Ascending,
  };

  public render() {
    const { loading, error, games } = this.props;
    const { sortCol, sortDir, page } = this.state;

    if (error) {
      return <ErrorContainer />;
    }

    const sortedGames = games.sort(getGameSortFunc(sortCol, sortDir));
    const start = page * PAGE_SIZE;
    const gamesPage = sortedGames.slice(start, start + PAGE_SIZE);
    const totalPages = Math.ceil(games.length / PAGE_SIZE);

    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Table compact={true} sortable={true} inverted={darkMode}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={this.getSorted(GameTableCol.Name)}
                  onClick={this.handleSort(GameTableCol.Name)}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(GameTableCol.Year)}
                  onClick={this.handleSort(GameTableCol.Year)}
                >
                  Year
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(GameTableCol.Platform)}
                  onClick={this.handleSort(GameTableCol.Platform)}
                >
                  Platform
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(GameTableCol.Genre)}
                  onClick={this.handleSort(GameTableCol.Genre)}
                >
                  Genre
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={this.getSorted(GameTableCol.RunCount)}
                  onClick={this.handleSort(GameTableCol.RunCount)}
                >
                  Runs
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading && [...Array(30)].map((_, i) => <GamesTableRow key={`loading-row-${i}`} />)}
              {!loading && gamesPage.map(game => <GamesTableRow key={game.id} game={game} />)}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="5" style={{ textAlign: 'center' }}>
                  <Pagination
                    activePage={page + 1}
                    totalPages={totalPages}
                    onPageChange={this.handlePageChange}
                    firstItem={null}
                    lastItem={null}
                    inverted={darkMode}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </GlobalContext.Consumer>
    );
  }

  private getSorted = (col: GameTableCol) =>
    sortDirToString(this.state.sortCol === col ? this.state.sortDir : undefined);

  private handleSort = (col: GameTableCol) => () => {
    const { sortCol, sortDir } = this.state;

    if (sortCol === col) {
      this.setState({
        sortDir: sortDir === SortDir.Ascending ? SortDir.Descending : SortDir.Ascending,
      });
    } else {
      this.setState({ sortCol: col, sortDir: GameSortDefaults[col] });
    }
  };

  private handlePageChange = (e: any, { activePage }: any) => {
    this.setState({ page: activePage - 1 });
  };
}

export default GamesTable;
export { GamesTable };
