import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Platform } from '../../types';
import LoadingTableCell from '../Shared/LoadingTableCell';

interface Props {
  platform?: Platform;
}

class PlatformsTableRow extends PureComponent<Props> {
  public render() {
    const { platform } = this.props;
    if (!platform) {
      return (
        <Table.Row>
          <LoadingTableCell loaderWidth={80} randomRange={20} />
          <LoadingTableCell loaderWidth={80} randomRange={20} />
          <LoadingTableCell loaderWidth={40} />
          <LoadingTableCell loaderWidth={20} randomRange={20} />
        </Table.Row>
      );
    }

    return (
      <Table.Row key={platform.id}>
        <Table.Cell>
          <Link to={`/platform/${platform.id}`}>{platform.name}</Link>
        </Table.Cell>
        <Table.Cell>{platform.company}</Table.Cell>
        <Table.Cell>{platform.launchYear}</Table.Cell>
        <Table.Cell>{platform.runCount}</Table.Cell>
      </Table.Row>
    );
  }
}

export default PlatformsTableRow;
export { PlatformsTableRow };
