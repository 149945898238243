import React, { PureComponent } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { GlobalContext } from '../App/GlobalContext';
import ErrorMessage from './ErrorMessage';

class ErrorContainer extends PureComponent {
  public render() {
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Container text={true} style={{ marginBottom: '1rem' }}>
            <Segment textAlign="center" size="large" inverted={darkMode}>
              <ErrorMessage />
            </Segment>
          </Container>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default ErrorContainer;
export { ErrorContainer };
