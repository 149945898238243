/* tslint:disable:object-literal-sort-keys */

import { SemanticWIDTHS } from 'semantic-ui-react';

export interface MenuItem {
  name: string;
  to: string;
}

export interface DesktopMenuSectionColumn {
  header?: string;
  items: MenuItem[];
}

export interface DesktopMenuSection {
  title: string;
  to: string;
  width: string;
  numCols: SemanticWIDTHS;
  columns: DesktopMenuSectionColumn[];
}

export interface MenuItems {
  mobile: MenuItem[];
  desktop: DesktopMenuSection[];
}

const mobileMenuItems: MenuItem[] = [
  { name: 'Events', to: '/events' },
  { name: 'Platforms', to: '/platforms' },
  { name: 'Genres', to: '/genres' },
  { name: 'Categories', to: '/categories' },
  { name: 'Games', to: '/games' },
  { name: 'Runners', to: '/runners' },
];

const eventsMenu: DesktopMenuSection = {
  title: 'Events',
  to: '/events',
  width: '350px',
  numCols: '3',
  columns: [
    {
      header: 'AGDQ',
      items: [
        { name: '2024', to: '/event/agdq-2024' },
        { name: '2023', to: '/event/agdq-2023' },
        { name: '2022', to: '/event/agdq-2022' },
        { name: '2021', to: '/event/agdq-2021' },
        { name: '2020', to: '/event/agdq-2020' },
        { name: '2019', to: '/event/agdq-2019' },
        { name: '2018', to: '/event/agdq-2018' },
        { name: '2017', to: '/event/agdq-2017' },
        { name: '2016', to: '/event/agdq-2016' },
        { name: '2015', to: '/event/agdq-2015' },
        { name: '2014', to: '/event/agdq-2014' },
        { name: '2013', to: '/event/agdq-2013' },
        { name: '2012', to: '/event/agdq-2012' },
        { name: '2011', to: '/event/agdq-2011' },
      ],
    },
    {
      header: 'SGDQ',
      items: [
        { name: '2024', to: '/event/sgdq-2024' },
        { name: '2023', to: '/event/sgdq-2023' },
        { name: '2022', to: '/event/sgdq-2022' },
        { name: '2021', to: '/event/sgdq-2021' },
        { name: '2020', to: '/event/sgdq-2020' },
        { name: '2019', to: '/event/sgdq-2019' },
        { name: '2018', to: '/event/sgdq-2018' },
        { name: '2017', to: '/event/sgdq-2017' },
        { name: '2016', to: '/event/sgdq-2016' },
        { name: '2015', to: '/event/sgdq-2015' },
        { name: '2014', to: '/event/sgdq-2014' },
        { name: '2013', to: '/event/sgdq-2013' },
        { name: '2012', to: '/event/sgdq-2012' },
        { name: '2011', to: '/event/sgdq-2011' },
      ],
    },
    {
      header: 'Other',
      items: [
        { name: 'Frost Fatales 2024', to: '/event/frostfatales-2024' },
        { name: 'GDQx 2023', to: '/event/gdqx-2023' },
        { name: 'Flame Fatales 2023', to: '/event/flamefatales-2023' },
        { name: 'Frost Fatales 2023', to: '/event/frostfatales-2023' },
        { name: 'Flame Fatales 2022', to: '/event/flamefatales-2022' },
        { name: 'Frost Fatales 2022', to: '/event/frostfatales-2022' },
        { name: 'Flame Fatales 2021', to: '/event/flamefatales-2021' },
        { name: 'Fleet Fatales 2020', to: '/event/fleetfatales-2020' },
        { name: 'Corona Relief', to: '/event/crdq' },
        { name: 'Frost Fatales 2020', to: '/event/frostfatales-2020' },
        { name: 'GDQx 2019', to: '/event/gdqx-2019' },
        { name: 'Games Done Quick Express', to: '/event/gdqx' },
        { name: 'Harvey Relief', to: '/event/hrdq' },
        { name: 'God of War', to: '/event/gowdq' },
        { name: 'Japan Relief', to: '/event/jrdq' },
        { name: 'Classic Games Done Quick', to: '/event/cgdq' },
      ],
    },
  ],
};

const platformsMenu: DesktopMenuSection = {
  title: 'Platforms',
  to: '/platforms',
  width: '500px',
  numCols: '4',
  columns: [
    {
      header: 'Nintendo',
      items: [
        { name: 'NES', to: '/platform/nes' },
        { name: 'SNES', to: '/platform/snes' },
        { name: 'N64', to: '/platform/nintendo-64' },
        { name: 'GameCube', to: '/platform/gamecube' },
        { name: 'Switch', to: '/platform/switch' },
      ],
    },
    {
      header: 'Sony',
      items: [
        { name: 'PlayStation', to: '/platform/playstation' },
        { name: 'PlayStation 2', to: '/platform/playstation-2' },
        { name: 'PlayStation 3', to: '/platform/playstation-3' },
        { name: 'PlayStation 4', to: '/platform/playstation-4' },
        { name: 'PlayStation 5', to: '/platform/playstation-5' },
      ],
    },
    {
      header: 'Sega',
      items: [
        { name: 'Master System', to: '/platform/master-system' },
        { name: 'Genesis', to: '/platform/genesis' },
        { name: 'Game Gear', to: '/platform/game-gear' },
        { name: 'Saturn', to: '/platform/saturn' },
        { name: 'Dreamcast', to: '/platform/dreamcast' },
      ],
    },
    {
      header: 'Other',
      items: [
        { name: 'PC', to: '/platform/pc' },
        { name: 'Arcade', to: '/platform/arcade' },
        { name: 'Xbox', to: '/platform/xbox' },
        { name: 'Xbox 360', to: '/platform/xbox-360' },
        { name: 'All Platforms', to: '/platforms' },
      ],
    },
  ],
};

const genresMenu: DesktopMenuSection = {
  title: 'Genres',
  to: '/genres',
  width: '350px',
  numCols: '3',
  columns: [
    {
      items: [
        { name: 'Action', to: '/genre/action' },
        { name: 'Metroidvania', to: '/genre/metroidvania' },
        { name: 'Racing', to: '/genre/racing' },
        { name: 'Run and Gun', to: '/genre/run-and-gun' },
        { name: 'Stealth', to: '/genre/stealth' },
      ],
    },
    {
      items: [
        { name: "Beat 'em up", to: '/genre/beat-em-up' },
        { name: 'Platformer', to: '/genre/platformer' },
        { name: 'Rhythm', to: '/genre/rhythm' },
        { name: 'Shooter', to: '/genre/shooter' },
        { name: 'Strategy', to: '/genre/strategy' },
      ],
    },
    {
      items: [
        { name: 'Horror', to: '/genre/horror' },
        { name: 'Puzzle', to: '/genre/puzzle' },
        { name: 'RPG', to: '/genre/rpg' },
        { name: 'Sports', to: '/genre/sports' },
      ],
    },
  ],
};

const categoriesMenu: DesktopMenuSection = {
  title: 'Categories',
  to: '/categories',
  width: '250px',
  numCols: '2',
  columns: [
    {
      header: 'Categories',
      items: [
        { name: 'World Records', to: '/category/world-record-runs' },
        {
          name: 'Awful Games Done Quick',
          to: '/category/awful-games-done-quick',
        },
        { name: 'Dev Commentary', to: '/category/developer-commentaries' },
        { name: 'TASBot', to: '/category/tool-assisted-speedruns' },
        { name: 'Handicapped', to: '/category/handicapped-runs' },
        { name: 'Races', to: '/category/races' },
        { name: 'Showcases', to: '/category/showcases' },
        { name: 'All Categories', to: '/categories' },
        { name: 'All Runners', to: '/runners' },
      ],
    },
    {
      header: 'Game Series',
      items: [
        { name: 'Final Fantasy', to: '/category/final-fantasy-speedruns' },
        { name: 'Pokemon', to: '/category/pokemon-speedruns' },
        { name: 'Zelda', to: '/category/zelda-speedruns' },
        { name: 'Metroid', to: '/category/metroid-speedruns' },
        { name: 'Mario', to: '/category/mario-speedruns' },
        { name: 'Mega Man', to: '/category/mega-man-speedruns' },
        { name: 'Donkey Kong', to: '/category/donkey-kong-speedruns' },
        { name: 'Disney', to: '/category/disney-speedruns' },
        { name: 'All Games', to: '/games' },
      ],
    },
  ],
};

const menuItems = {
  mobile: mobileMenuItems,
  desktop: [eventsMenu, platformsMenu, genresMenu, categoriesMenu],
};
export default menuItems;
