import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';
import { Genre } from '../../types';
import { backgroundColour, cardStyle, genreDescription, textColour } from '../../utils';
import { GlobalContext } from '../App/GlobalContext';
import { Markdown } from '../Shared';

interface Props {
  genre: Genre;
}

class GenreCard extends PureComponent<Props> {
  public render() {
    const { genre } = this.props;
    return (
      <GlobalContext.Consumer>
        {({ darkMode }) => (
          <Card as={Link} to={`/genre/${genre.id}`} style={cardStyle(darkMode)}>
            <Image src={`/img/genre/${genre.id}.jpg`} width={290} height={290} alt={genre.name} />
            <Card.Content style={backgroundColour(darkMode)}>
              <Card.Header style={textColour(darkMode)}>{genre.name}</Card.Header>
              <Card.Description style={textColour(darkMode)}>
                <Markdown markdown={genreDescription(genre, true)} />
              </Card.Description>
            </Card.Content>
          </Card>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default GenreCard;
export { GenreCard };
