import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Container } from 'semantic-ui-react';
import { CATEGORY_RUNS_QUERY } from '../../apollo';
import { RunTableCol, SortDir } from '../../types';
import { SiteCategorySegment } from '../HeaderSegments';
import RunTable from '../RunTable';
import Ad from '../Shared/Ad';

const cols: RunTableCol[] = [
  RunTableCol.Vods,
  RunTableCol.Game,
  RunTableCol.Platform,
  RunTableCol.Category,
  RunTableCol.Event,
  RunTableCol.Runners,
  RunTableCol.Time,
];

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

class SiteCategoryPage extends PureComponent<Props> {
  public render() {
    const { match } = this.props;
    return (
      <Fragment>
        <SiteCategorySegment categoryId={match.params.id} />
        <Container>
          <Ad key="SiteCategoryPage" />
          <RunTable
            key={`category-${match.params.id}`}
            query={CATEGORY_RUNS_QUERY}
            queryVars={{ category: match.params.id }}
            defaultSortCol={RunTableCol.Game}
            defaultSortDir={SortDir.Ascending}
            cols={cols}
          />
        </Container>
      </Fragment>
    );
  }
}

export default SiteCategoryPage;
export { SiteCategoryPage };
