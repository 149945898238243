import React, { PureComponent, ReactNode } from 'react';
import { Card, Container } from 'semantic-ui-react';
import { LoadingCards } from '../Shared';
import Ad from '../Shared/Ad';
import ErrorContainer from '../Shared/ErrorContainer';

interface Props {
  loading: boolean;
  error: boolean;
  children: ReactNode;
  adKey: string;
  numPlaceholders?: number;
}

class CardIndexPage extends PureComponent<Props> {
  public render() {
    const { loading, error, children, adKey, numPlaceholders } = this.props;

    if (loading) {
      return <LoadingCards numPlaceholders={numPlaceholders} />;
    }
    if (error) {
      return <ErrorContainer />;
    }

    return (
      <Container>
        <Ad key={adKey} />
        <Card.Group style={{ marginTop: '1em' }} centered={true}>
          {children}
        </Card.Group>
      </Container>
    );
  }
}

export default CardIndexPage;
export { CardIndexPage };
