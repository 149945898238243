/* tslint:disable:max-classes-per-file */

import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Event, Game, Genre, Platform, Runner } from '../../types';
import { RunnerLink } from './RunnerLink';

interface GenreLinkProps {
  genre: Genre;
}
export class GenreLink extends PureComponent<GenreLinkProps> {
  public render() {
    const { genre } = this.props;
    return <Link to={`/genre/${genre.id}`}>{genre.name}</Link>;
  }
}

interface RunnerLinksProps {
  runners: Runner[];
  showPopup: boolean;
}
export class RunnerLinks extends PureComponent<RunnerLinksProps> {
  public render() {
    const { runners, showPopup } = this.props;
    return (
      <Fragment>
        {runners.map((runner, i) => (
          <Fragment key={i}>
            {!!i && ', '}
            <RunnerLink runner={runner} showPopup={showPopup} />
          </Fragment>
        ))}
      </Fragment>
    );
  }
}

interface PlatformLinkProps {
  platform: Platform;
}
export class PlatformLink extends PureComponent<PlatformLinkProps> {
  public render() {
    const { platform } = this.props;
    return <Link to={`/platform/${platform.id}`}>{platform.name}</Link>;
  }
}

interface EventLinkProps {
  event: Event;
}
export class EventLink extends PureComponent<EventLinkProps> {
  public render() {
    const { event } = this.props;
    return <Link to={`/event/${event.id}`}>{event.shortName}</Link>;
  }
}

interface GameWithYearLinkProps {
  game: Game;
}
export class GameWithYearLink extends PureComponent<GameWithYearLinkProps> {
  public render() {
    const { game } = this.props;
    return (
      <Link to={`/game/${game.id}`}>
        {game.name} ({game.year})
      </Link>
    );
  }
}

interface GameNoYearLinkProps {
  game: Game;
}
export class GameNoYearLink extends PureComponent<GameNoYearLinkProps> {
  public render() {
    const { game } = this.props;
    return <Link to={`/game/${game.id}`}>{game.name}</Link>;
  }
}
