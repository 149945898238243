import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Container, Header } from 'semantic-ui-react';

import { EVENT_QUERY } from '../../apollo';
import { Event, EventQueryData, EventQueryVars } from '../../types';
import { eventDescription } from '../../utils';
import { ErrorMessage, Markdown } from '../Shared';
import { StandardHelmet } from '../Shared/StandardHelmet';
import { LoadingSegment } from './LoadingSegment';

interface Props {
  eventId: string;
}

class EventSegment extends PureComponent<Props> {
  public render() {
    const { eventId } = this.props;
    return (
      <Query<EventQueryData, EventQueryVars> query={EVENT_QUERY} variables={{ event: eventId }}>
        {({ data, error, loading }: QueryResult<EventQueryData, EventQueryVars>) => {
          const event = this.getEvent(data);
          const loadingFirstTime = loading && !event;
          return (
            <Container text={true} style={{ marginBottom: '1rem' }}>
              <StandardHelmet title={this.getTitle(event)} description={this.getDescription(eventId, event)} />
              <LoadingSegment textAlign="center" size="large" loading={loadingFirstTime}>
                {!loadingFirstTime && !error && event && (
                  <Fragment>
                    <Header>{event.name}</Header>
                    <Markdown markdown={`${eventDescription(event)}\n\n${this.getDescription(eventId, event)}`} />
                  </Fragment>
                )}
                {!loadingFirstTime && error && <ErrorMessage />}
              </LoadingSegment>
            </Container>
          );
        }}
      </Query>
    );
  }

  private getTitle(event?: Event) {
    const title = 'GDQ VODs';
    return event ? `${title} · ${event.shortName}` : title;
  }

  private getDescription(eventId: string, event?: Event) {
    const nameToUse = event ? event.shortName : eventId;
    return `Watch the VODs of all the runs from ${nameToUse} here and browse through related games, runners, and special categories.`;
  }

  private getEvent(eventData?: EventQueryData) {
    return eventData && eventData.events ? eventData.events[0] : undefined;
  }
}
export { EventSegment };
