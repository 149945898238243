import React, { Fragment, PureComponent } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { CATEGORIES_QUERY } from '../../apollo';
import { CategoryQueryData } from '../../types';
import { sortSiteCategories } from '../../utils';
import { SiteCategoryCard } from '../Cards';
import StandardHelmet from '../Shared/StandardHelmet';
import CardIndexPage from './CardIndexPage';

const title = 'GDQ VODs · Categories';
const description =
  'Browse through the VODs for the special categories listed on GDQ VODs, including world records and TASBot runs.';

class SiteCategoriesPage extends PureComponent {
  public render() {
    return (
      <Query<CategoryQueryData> query={CATEGORIES_QUERY}>
        {({ data, error, loading }: QueryResult<CategoryQueryData>) => {
          const categories = this.getCategories(data);
          const loadingFirstTime = loading && categories.length === 0;
          return (
            <Fragment>
              <StandardHelmet title={title} description={description} />
              <CardIndexPage adKey="CategoriesPage" loading={loadingFirstTime} error={!!error} numPlaceholders={18}>
                {categories
                  .slice()
                  .sort(sortSiteCategories)
                  .map((cat, i) => (
                    <SiteCategoryCard key={i} siteCategory={cat} />
                  ))}
              </CardIndexPage>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private getCategories(data?: CategoryQueryData) {
    return (data && data.siteCategories) || [];
  }
}

export default SiteCategoriesPage;
export { SiteCategoriesPage };
